/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import ChevronDown from "images/icons/chevron-down.svg?inline";

const EmptyContent = ({ title, buttonText, continueURL, iconComponent }) => {
  return (
    <>
      <div className="text-center mt-11">
        <div className="mb-9">{iconComponent}</div>
        <div className="mb-9">
          <span>{title}</span>
        </div>
        <div className="mb-13 mx-auto text-center">
          <a
            type="button"
            href={continueURL}
            className="font-medium text-grey-400 px-4 py-2 border border-grey-200"
          >
            {buttonText}
            <ChevronDown className="ml-2 transform -rotate-90 inline-block fill-current text-primary" />
          </a>
        </div>
      </div>
    </>
  );
};

EmptyContent.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  continueURL: PropTypes.string,
  iconComponent: PropTypes.node,
};

export default EmptyContent;
