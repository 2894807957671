import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import prettyMilliseconds from "pretty-ms";
import { t } from "i18n-js";
import { getVimeo } from "utils/vimeo";
import CoursesIcon from "../../images/icons/courses.svg";
import ClockIcon from "../../images/icons/clock.svg";
import CheckCircleIcon from "../../images/icons/check-circle.svg";

const CourseMetadata = ({ lessons, examCount }) => {
  const [totalSeconds, setTotalSeconds] = useState();
  useEffect(() => {
    Promise.all(
      lessons.map(({ videoURL }) =>
        getVimeo(videoURL).then(({ duration }) => duration)
      )
    ).then((durations) => {
      setTotalSeconds(durations.reduce((sum, current) => sum + current, 0));
    });
  }, [lessons]);
  return (
    <div className="py-4 flex">
      <div className="text-xs w-1/3">
        <div className="flex items-center">
          <img src={CoursesIcon} alt="plan" />
          <div className="font-medium ml-1">{t("courses.show.plan")}</div>
        </div>
        <div className="text-tiny mt-1 md:text-xs">
          {t("courses.show.lesson_count", { n: lessons.length })}
        </div>
      </div>
      <div className="text-xs w-1/3">
        <div className="flex items-center">
          <img src={ClockIcon} alt="time" />
          <div className="font-medium ml-1">{t("courses.show.time")}</div>
        </div>
        <div className="text-tiny mt-1 md:text-xs">
          {totalSeconds ? prettyMilliseconds(totalSeconds * 1000) : "-"}
        </div>
      </div>
      <div className="text-xs w-1/3">
        <div className="flex items-center">
          <img src={CheckCircleIcon} className="flex-none" alt="certificate" />
          <div className="font-medium ml-1">
            {t("courses.show.certificate.title")}
          </div>
        </div>
        <div className="text-tiny mt-1 md:text-xs">
          {examCount > 0
            ? t("courses.show.certificate.require_test")
            : t("courses.show.certificate.none")}
        </div>
      </div>
    </div>
  );
};

CourseMetadata.propTypes = {
  lessons: PropTypes.array,
  examCount: PropTypes.number,
};

export default CourseMetadata;
