/* eslint-disable no-param-reassign */
import "../stylesheets/application.scss";
import Rails from "@rails/ujs";
import ReactRailsUJS from "react_ujs";
import "../lib/publicPath";
import { toast } from "react-toastify";
import I18n from "../lib/i18n.js.erb";

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(require.context("components", true));
Rails.start();
toast.configure();
I18n.locale = global.I18N_LOCALE;

document.addEventListener("DOMContentLoaded", () => {
  const elements = document.querySelectorAll('[type="password"]');
  elements.forEach(function (elem) {
    elem.parentNode
      .querySelector("[class*=eye-icons]")
      .addEventListener("click", function () {
        if (elem.type === "password") {
          elem.type = "text";
          this.children["eye-close"].setAttribute("class", "hidden");
          this.children["eye-show"].setAttribute("class", "block");
        } else {
          elem.type = "password";
          this.children["eye-close"].setAttribute("class", "block");
          this.children["eye-show"].setAttribute("class", "hidden");
        }
      });
  });

  if (window.FLASH_MESSAGE) {
    if (window.FLASH_TYPE === "message" || window.FLASH_TYPE === "notice") {
      toast.info(window.FLASH_MESSAGE, {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
    if (window.FLASH_TYPE === "alert") {
      toast.error(window.FLASH_MESSAGE, {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
    if (window.FLASH_TYPE === "success") {
      toast.success(window.FLASH_MESSAGE, {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  }
});
