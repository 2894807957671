import React, { useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { toast } from "react-toastify";
import api from "api";
import FavoriteIcon from "../../images/icons/favorite.svg?inline";
import FavoritedIcon from "../../images/icons/favorited.svg?inline";

async function createFavoriteRecipe(recipeId) {
  await api.post(`/recipes/${recipeId}/favorites`);
}

async function updateFavoriteRecipe(recipeId, favorite) {
  await api.put(`/recipes/${recipeId}/favorites`, {
    favoritable: favorite ? "favorite" : "unfavorite",
  });
}

const FavoriteButton = ({
  redirect,
  recipeId,
  defaultIsFavorited,
  showText,
  onClick,
}) => {
  const [isFavorited, setIsFavorited] = useState(defaultIsFavorited);
  const handleClick = async (e) => {
    if (onClick) {
      onClick(e);
    }

    if (redirect) {
      window.location.href = redirect;
      return;
    }

    try {
      if (!defaultIsFavorited) {
        await createFavoriteRecipe(recipeId);
      } else {
        await updateFavoriteRecipe(recipeId, !isFavorited);
      }

      setIsFavorited(!isFavorited);
    } catch (error) {
      console.error(error);
      toast.error(t("errors.messages.server_error"));
    }
  };

  return (
    <button
      className="btn inline-flex items-center"
      type="button"
      onClick={handleClick}
    >
      {isFavorited ? <FavoritedIcon /> : <FavoriteIcon />}
      {showText && (
        <span className="ml-2 font-medium">
          {isFavorited
            ? t("recipes.show.favorite.favorited")
            : t("recipes.show.favorite.button")}
        </span>
      )}
    </button>
  );
};

FavoriteButton.propTypes = {
  redirect: PropTypes.string,
  defaultIsFavorited: PropTypes.bool,
  recipeId: PropTypes.number,
  showText: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FavoriteButton;
