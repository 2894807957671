import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactModal from "react-modal";
import DotIcon from "../../images/icons/dot.svg?inline";
import MagnifierIcon from "../../images/icons/magnifier.svg?inline";
import CloseIcon from "../../images/icons/close.svg?inline";

const RecipeIngredientList = ({ ingredients }) => {
  const [currentIngredientModal, setCurrentIngredientModal] = useState("");
  const closeIngredientModal = () => {
    setCurrentIngredientModal("");
  };

  return ingredients.map(({ id, name, quantity, imageURL, link, note }) => (
    <ul className="mb-6 last:mb-0 flex items-baseline" key={id}>
      <DotIcon className="flex-none fill-current text-grey-300 mr-5" />
      <li className="list-none flex flex-wrap items-center">
        {quantity && <span className="mr-1">{quantity}</span>}
        <a
          className={classNames("mr-2", link && "text-brand-300")}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
        {imageURL && (
          <>
            <button
              className="btn w-7 h-7 p-0 flex items-center justify-center mr-2"
              type="button"
              onClick={() => setCurrentIngredientModal(id)}
            >
              <MagnifierIcon />
            </button>
            <ReactModal
              isOpen={currentIngredientModal === id}
              overlayClassName="fixed z-50 inset-0 bg-grey-400 bg-opacity-70 flex items-center justify-center"
              className="bg-none outline-none"
              onRequestClose={closeIngredientModal}
              ariaHideApp={false}
            >
              <div className="relative max-w-xl">
                <button
                  type="button"
                  className="absolute w-10 h-10 bottom-100 right-0 bg-grey-400 bg-opacity-70 flex justify-center items-center"
                  onClick={closeIngredientModal}
                >
                  <CloseIcon className="text-grey-300 fill-current" />
                </button>
                <img src={imageURL} alt={name} />
              </div>
            </ReactModal>
          </>
        )}
        {note && <span className="text-grey-300 font-light">({note})</span>}
      </li>
    </ul>
  ));
};

RecipeIngredientList.propTypes = {
  ingredients: PropTypes.array,
};

export default RecipeIngredientList;
