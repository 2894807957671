import axios from "axios";
import I18n from "i18n-js";

const api = axios.create({
  baseURL: "/api/",
  params: { locale: I18n.currentLocale() },
  withCredentials: true,
  headers: { accept: "application/json" },
});

export default api;
