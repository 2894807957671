import React from "react";
import PropTypes from "prop-types";

import CardsCarousel from "components/common/CardsCarousel";
import RecipeCard from "./RecipeCard";

const RecipeCardsCarousel = ({ title, isMobile, recipes = [] }) => {
  return (
    <CardsCarousel
      title={title}
      isMobile={isMobile}
      content={recipes}
      cardComponent={RecipeCard}
      moreCardsPerSlide
    />
  );
};

RecipeCardsCarousel.propTypes = {
  title: PropTypes.string,
  isMobile: PropTypes.bool,
  recipes: PropTypes.array,
};

export default RecipeCardsCarousel;
