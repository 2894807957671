import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import {
  clearFieldFilters,
  toggleFieldFilters,
  countSelectedFilters,
  countSelectedFieldOptions,
} from "utils/recipe";
import _ from "lodash";
import RecipeFilterMenuBase from "./RecipeFilterMenuBase";
import RecipeFilterFieldOptions from "./RecipeFilterFieldOptions";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";
import RecipeFilterFieldHeader from "./RecipeFilterFieldHeader";

const RecipeFilterMobileMenu = ({
  selectedFilters = {},
  setSelectedFilters,
  isOpen,
  filters = {},
  onOpen,
  onClose,
}) => {
  const [currentField, setCurrentField] = useState("");
  const count = countSelectedFilters(selectedFilters);

  return (
    <RecipeFilterMenuBase
      isOpen={isOpen}
      title={
        <>
          <span className="font-medium">{t("recipes.filter.title")}</span>
          <span className="ml-1 text-grey-300">({count})</span>
        </>
      }
      menuTitle={
        <div className="flex justify-between items-center">
          <span>
            <span className="font-medium">{t("recipes.filter.title")}</span>
          </span>
          <button
            className="btn text-xs font-medium"
            onClick={() => {
              setSelectedFilters({});
            }}
            type="button"
          >
            {t("recipes.filter.clear_all")}
          </button>
        </div>
      }
      onOpen={onOpen}
      onClose={onClose}
    >
      {Object.entries(filters).map(([field, options]) => {
        const selectedOptionCount = countSelectedFieldOptions(
          selectedFilters,
          field
        );
        return (
          <React.Fragment key={field}>
            <RecipeFilterFieldHeader
              className="mb-4 last:mb-0"
              field={field}
              onClick={() => {
                setCurrentField(field);
              }}
              selectedOptionCount={selectedOptionCount}
              isAllSelected={selectedOptionCount === options.length}
            />
            <div
              className={classNames(
                "fixed z-10 inset-0 bg-white transform transition-transform duration-200 overflow-auto",
                currentField === field ? "translate-x-0" : "-translate-x-full"
              )}
            >
              <div className="container mx-auto">
                <button
                  className="h-10 flex items-center"
                  type="button"
                  onClick={() => {
                    setCurrentField("");
                  }}
                >
                  <ChevronDown className="transform rotate-90 text-brand-300 fill-current" />
                </button>
                <div className="flex justify-between items-center">
                  <span className="font-medium text-body">
                    {t(`recipes.filter.options.${field}`)}
                  </span>
                  <button
                    className="btn text-xs font-medium"
                    type="button"
                    onClick={() => {
                      setSelectedFilters(
                        clearFieldFilters(field, selectedFilters)
                      );
                    }}
                  >
                    {t("recipes.filter.clear")}
                  </button>
                </div>
                <RecipeFilterFieldOptions
                  className="border border-grey-200 py-7 mt-7"
                  options={filters[field]}
                  onSelect={(value) => {
                    setSelectedFilters(
                      toggleFieldFilters(field, value, selectedFilters)
                    );
                  }}
                  isChecked={(value) =>
                    _.get(selectedFilters, [field, value], false)
                  }
                />
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </RecipeFilterMenuBase>
  );
};

RecipeFilterMobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  filters: PropTypes.object,
  selectedFilters: PropTypes.object,
  setSelectedFilters: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RecipeFilterMobileMenu;
