import React from "react";
import PropTypes from "prop-types";

import CardsCarousel from "components/common/CardsCarousel";
import CourseCard from "./CourseCard";

const CourseCardsCarousel = ({ title, isMobile, courses = [] }) => {
  return (
    <CardsCarousel
      title={title}
      isMobile={isMobile}
      content={courses}
      cardComponent={CourseCard}
    />
  );
};

CourseCardsCarousel.propTypes = {
  title: PropTypes.string,
  isMobile: PropTypes.bool,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      thumbnailURL: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default CourseCardsCarousel;
