import React, { useState } from "react";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf";
import classNames from "classnames";
import breakpoints from "lib/breakpoints";
import useWindowSize from "lib/resize";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";

const sliderMargin = 48;
const initialPage = 2;

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

const MagazineViewer = ({ className, url }) => {
  const [pageCount, setPageCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [scale, setScale] = useState(1.0);
  const [screenWidth, screenHeight] = useWindowSize();

  const pagesPerScreen =
    screenWidth < Number(breakpoints.md.replace("px", "")) ? 1 : 2;

  const height = screenHeight - sliderMargin - 100;

  let sliderLabel = currentPage;
  if (pagesPerScreen > 1) {
    sliderLabel = `${currentPage}-${currentPage + pagesPerScreen - 1}`;
  }

  function onDocumentLoadSuccess(pdf) {
    setPageCount(pdf.numPages);
  }

  function onPageLoadSuccess(page) {
    setScale(
      Math.min(
        screenWidth / pagesPerScreen / page.originalWidth,
        (screenHeight - sliderMargin - 100) / page.originalHeight
      )
    );
  }

  function onClickPrev(e) {
    e.preventDefault();

    if (currentPage > pagesPerScreen) {
      setCurrentPage(currentPage - pagesPerScreen);
    }
  }

  function onClickNext(e) {
    e.preventDefault();

    if (
      currentPage < initialPage &&
      currentPage + pagesPerScreen > initialPage
    ) {
      setCurrentPage(initialPage);
    } else if (currentPage + pagesPerScreen < pageCount) {
      setCurrentPage(currentPage + pagesPerScreen);
    }
  }

  function onSliderMouseDown(e) {
    e.preventDefault();

    const target = e.currentTarget;
    const boundingDimensions = target.getBoundingClientRect();

    const progress = Math.min(
      Math.max(
        (e.clientX - boundingDimensions.left - sliderMargin) /
          (boundingDimensions.width - sliderMargin * 2),
        0.0
      ),
      1.0
    );

    const page = Math.floor((pageCount - pagesPerScreen - 1) * progress) + 1;

    setCurrentPage(page);
  }

  let progress = 0;
  if (pageCount > 0) {
    progress = (currentPage / pageCount) * 100.0;
  }

  const children = Array(pagesPerScreen)
    .fill()
    .map((_, i) => {
      const key = currentPage + i;
      if (key < pageCount) {
        return (
          <Page
            pageNumber={key}
            scale={scale}
            key={key}
            onLoadSuccess={onPageLoadSuccess}
          />
        );
      }
      return <div key={key} />;
    });

  return (
    <div
      className={classNames("relative py-10", className)}
      style={{ background: "#ccc" }}
    >
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        options={{ workerSrc: "/pdf.worker.js" }}
      >
        <div
          className="flex items-center justify-center"
          style={{ minHeight: height }}
        >
          {children}
        </div>
      </Document>
      <div
        aria-hidden="true"
        className="pages-slider cursor-pointer px-10 py-10"
        onMouseDown={onSliderMouseDown}
      >
        <div className="wrapper border-b relative ">
          <div
            aria-hidden="true"
            className="caret inline-block bg-grey-400 py-3 px-6 rounded-full text-white absolute bottom-0 "
            style={{ bottom: "-25px", left: `calc(${progress}% - 25px)` }}
          >
            <span className="select-none whitespace-nowrap">{sliderLabel}</span>
          </div>
        </div>
      </div>
      <button
        aria-hidden="true"
        className="prev absolute left-0 top-0 bg-grey-400 bg-opacity-70 py-9 px-2 cursor-pointer"
        onClick={onClickPrev}
        style={{ top: "calc(50% - 100px/2)" }}
        type="button"
      >
        <ChevronDown
          className={classNames(
            "inline-block fill-current text-white transform rotate-90"
          )}
        />
      </button>
      <button
        aria-hidden="true"
        className="next absolute right-0 top-0 bg-grey-400 bg-opacity-70 py-9 px-2 cursor-pointer"
        onClick={onClickNext}
        style={{ top: "calc(50% - 100px/2)" }}
        type="button"
      >
        <ChevronDown
          className={classNames(
            "inline-block fill-current text-white transform -rotate-90"
          )}
        />
      </button>
    </div>
  );
};

MagazineViewer.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
};

export default MagazineViewer;
