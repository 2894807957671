import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import ContentListing from "components/common/ContentListing";
import api from "api";
import EmptyContent from "components/common/EmptyContent";
import CertificateGreen from "images/icons/certificate-green.png";
import EmptyContentIcon from "../../images/icons/empty-my-certificate.svg?inline";
import MyCertificateCard from "./MyCertificateCard";

async function fetchCertificates({ page, perPage }) {
  const response = await api.get("/my_certificates", {
    params: { page, per_page: perPage },
  });

  return {
    content: response.data,
    pageCount: response.headers["page-count"],
  };
}

const MyCertificateListing = ({
  courses = [],
  onLoadMore = fetchCertificates,
}) => {
  const fetch = useCallback(
    ({ page, perPage }) => {
      return onLoadMore({ page, perPage });
    },
    [onLoadMore]
  );

  return (
    <>
      {courses.length > 0 ? (
        <div>
          <div className="container mx-auto">
            <div className="w-full px-7 py-4 bg-success-100 inline-flex items-center mt-8 mb-8 md:mt-7 md:mb-10">
              <img
                src={CertificateGreen}
                width="42"
                height="42"
                alt="certificate"
              />
              <span className="text-sm ml-6">
                {t("my_certificates.description")}
              </span>
            </div>
          </div>
          <ContentListing
            itemComponent={MyCertificateCard}
            initialContent={courses}
            onLoadMore={fetch}
            itemClassName="col-span-12 md:col-start-2 md:col-end-12 md:h-certificate-card h-mobile-certificate-card"
          />
        </div>
      ) : (
        <EmptyContent
          title={t("my_courses.empty_content.title")}
          buttonText={t("my_courses.empty_content.link_text")}
          continueURL="courses"
          iconComponent={<EmptyContentIcon className="mx-auto" />}
        />
      )}
    </>
  );
};

MyCertificateListing.propTypes = {
  courses: PropTypes.array,
  onLoadMore: PropTypes.func,
};

export default MyCertificateListing;
