export function getPaginationItems(currentPage, pageRange) {
  const start = Math.floor((currentPage - 1) / pageRange) * pageRange;

  return Array(pageRange)
    .fill()
    .map((_, i) => i + start + 1);
}

export function getPaginationItemTypes(items, currentPage) {
  const needle = items[items.length - 4] || items[0];
  if (currentPage >= needle) {
    return items.map((item) => {
      if (item < needle) {
        return "hidden";
      }

      return "page";
    });
  }

  if (currentPage === items[0]) {
    return items.map((item, i) => {
      if (i === 0 || i === 1 || i === items.length - 1) {
        return "page";
      }

      if (i === 2) {
        return "ellipsis";
      }

      return "hidden";
    });
  }

  return items.map((item, i) => {
    if (
      currentPage - item === 1 ||
      currentPage === item ||
      i === items.length - 1
    ) {
      return "page";
    }

    if (item - currentPage === 1) {
      return "ellipsis";
    }

    return "hidden";
  });
}
