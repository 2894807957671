import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { locales, getLabel } from "utils/locale";
import useOnClickOutside from "hooks/useOnClickOutside";
import ChevronDown from "../images/icons/chevron-down.svg?inline";
import Globe from "../images/icons/globe.svg?inline";

const LanguageSwitcher = ({ className, locale = "th", localeURLs }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div ref={ref} className={className}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={classNames(
          "inline-block mr-9 focus:outline-none font-semibold pl-1",
          isOpen && "text-brand-300"
        )}
        type="button"
      >
        <Globe className="inline-block fill-current" />
        <span className="mx-1 font-medium">{getLabel(locale)}</span>
        <ChevronDown
          className={classNames(
            "inline-block fill-current text-primary",
            isOpen && "transform rotate-180"
          )}
        />
      </button>
      <div
        className={classNames(
          "absolute left-auto top-100 border rounded-sm border-grey-200 -mt-5 opacity-0 pointer-events-none transition-opacity duration-150",
          isOpen && "opacity-100 pointer-events-auto"
        )}
      >
        {locales.map(({ icon, alt, value }) => (
          <a
            key={value}
            className={classNames(
              "block p-2 w-full text-left bg-white hover:bg-brand-100",
              locale === value && "bg-brand-100"
            )}
            href={localeURLs[value]}
          >
            <img className="inline-block" src={icon} alt={alt} />
            <span className="mx-1 font-medium">{getLabel(value)}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

LanguageSwitcher.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.oneOf(["en", "th"]),
  localeURLs: PropTypes.object,
};

export default LanguageSwitcher;
