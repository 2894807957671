import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactMultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import CarouselControl from "./CarouselControl";

const responsive = {
  default: {
    breakpoint: { min: 0, max: Infinity },
    items: 1,
  },
  mobile: {
    breakpoint: { min: 0, max: 679 },
    items: 1,
    partialVisibilityGutter: 112,
  },
  tablet: {
    breakpoint: { min: 680, max: 969 },
    items: 1,
    partialVisibilityGutter: 240,
  },
};

const Carousel = ({
  title,
  children,
  customButtonGroup,
  partialVisible,
  cursorPosition,
  cursorBorder,
  innerContainerClassName,
}) => {
  const carouselRef = useRef();
  const intervalRef = useRef(null);
  const stopAutoPlay = () => clearInterval(intervalRef.current);
  useEffect(() => {
    if (!carouselRef.current) {
      return;
    }

    const carousel = carouselRef.current;
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (carousel.state.currentSlide < carousel.state.totalItems - 1) {
        carousel.next();
      } else {
        carousel.goToSlide(0);
      }
    }, 5000);

    // eslint-disable-next-line consistent-return
    return stopAutoPlay;
  }, [carouselRef]);

  return (
    <div className="relative overflow-hidden">
      {title}
      <ReactMultiCarousel
        className={innerContainerClassName}
        draggable={false}
        responsive={responsive}
        arrows={false}
        ssr
        partialVisible={partialVisible}
        keyBoardControl
        transitionDuration={500}
        renderButtonGroupOutside
        customButtonGroup={
          customButtonGroup ? (
            React.cloneElement(customButtonGroup, { onClick: stopAutoPlay })
          ) : (
            <CarouselControl
              onClick={stopAutoPlay}
              cursorPosition={cursorPosition}
              cursorBorder={cursorBorder}
            />
          )
        }
        ref={carouselRef}
      >
        {children}
      </ReactMultiCarousel>
    </div>
  );
};

Carousel.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  customButtonGroup: PropTypes.node,
  cursorPosition: PropTypes.string,
  cursorBorder: PropTypes.string,
  partialVisible: PropTypes.bool,
  innerContainerClassName: PropTypes.string,
};

export default Carousel;
