import React from "react";
import PropTypes from "prop-types";

import CardsCarousel from "components/common/CardsCarousel";
import MagazineCard from "./MagazineCard";

const MagazineCardsCarousel = ({ title, isMobile, magazines = [] }) => {
  return (
    <CardsCarousel
      title={title}
      isMobile={isMobile}
      content={magazines}
      cardComponent={MagazineCard}
    />
  );
};

MagazineCardsCarousel.propTypes = {
  title: PropTypes.string,
  isMobile: PropTypes.bool,
  magazines: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      volume: PropTypes.string.isRequired,
      imageURL: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default MagazineCardsCarousel;
