import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getPaginationItems, getPaginationItemTypes } from "utils/pagination";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";

const Pagination = ({
  currentPage = 1,
  pageRange = 8,
  totalPages = Infinity,
  baseURL = "#",
  onPageChange,
}) => {
  const items = getPaginationItems(currentPage, pageRange).filter(
    (item) => item <= totalPages
  );

  const itemTypes = getPaginationItemTypes(items, currentPage);

  const nextPrevClassName =
    "btn w-10 h-10 flex justify-center items-center md:absolute top-0 right-0 flex-none";

  return (
    <div className="flex relative sm:mt-auto mt-10">
      <button
        className={classNames(
          nextPrevClassName,
          "md:mr-9 md:transform md:-translate-x-full"
        )}
        type="button"
        disabled={currentPage === 1}
        onClick={() => {
          const previousPage = currentPage - 1;
          if (onPageChange) {
            onPageChange(previousPage);
            return;
          }

          window.location.href = `${baseURL}?page=${previousPage}`;
        }}
      >
        <ChevronDown className="transform rotate-90 fill-current" />
      </button>
      <div className="flex-1 flex justify-center gap-3 md:justify-start">
        {items.map((item, i) => {
          const type = itemTypes[i];
          return (
            <a
              key={item}
              className={classNames(
                "inline-flex justify-center items-center w-8 h-10 box-content",
                {
                  "border-b-2 border-primary": currentPage === item,
                  "hidden sm:inline-flex": type === "hidden",
                }
              )}
              onClick={(e) => {
                if (onPageChange) {
                  e.preventDefault();
                  onPageChange(item);
                }
              }}
              href={`${baseURL}?page=${item}`}
            >
              {type === "ellipsis" && <span className="sm:hidden">...</span>}
              <span className={type === "ellipsis" ? "hidden sm:inline" : ""}>
                {item}
              </span>
            </a>
          );
        })}
      </div>
      <button
        className={nextPrevClassName}
        type="button"
        disabled={currentPage === totalPages}
        onClick={() => {
          const nextPage = currentPage + 1;
          if (onPageChange) {
            onPageChange(nextPage);
            return;
          }

          window.location.href = `${baseURL}?page=${nextPage}`;
        }}
      >
        <ChevronDown className="transform -rotate-90 fill-current" />
      </button>
    </div>
  );
};

Pagination.propTypes = {
  baseURL: PropTypes.string,
  currentPage: PropTypes.number,
  pageRange: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default Pagination;
