import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Hamburger from "hamburger-react";
import classNames from "classnames";
import MHALogo from "images/mha-logo.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import HeaderMenuCategories from "./HeaderMenuCategories";
import HeaderMenu from "./HeaderMenu";
import "./Header.scss";
import LanguageSwitcher from "./LanguageSwitcher";
import MobileLanguageSwitcher from "./MobileLanguageSwitcher";
import HeaderUserMenu from "./HeaderUserMenu";

const expandableItems = ["courses", "articles"];

const Header = ({
  logoURL,
  menuItemCategories = {},
  contentLinks = {},
  linkItems = [],
  locale,
  homeLink,
  localeURLs = {},
  userMenu = {},
}) => {
  const [openedItemKey, setOpenedItemKey] = useState("");
  const closeItem = () => {
    setOpenedItemKey("");
  };
  const toggleItem = (key) => {
    if (openedItemKey === key) {
      closeItem();
      return;
    }
    setOpenedItemKey(key);
  };

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, closeItem);

  return (
    <div ref={ref}>
      <div className="h-mobile-header md:h-header border-b border-grey-150 absolute z-40 w-full bg-white">
        <nav className="container mx-auto h-full flex items-center justify-between text-grey-400">
          <div
            className={classNames(
              "bg-white flex items-center md:static",
              isMobileMenuOpen && "container fixed top-0 inset-x-0 z-40"
            )}
          >
            <div
              className={classNames(
                "md:hidden hamburger-container relative z-20",
                isMobileMenuOpen && "active"
              )}
            >
              <Hamburger
                size={22}
                distance="sm"
                toggled={isMobileMenuOpen}
                onToggle={() => setMobileMenuOpen(!isMobileMenuOpen)}
              />
            </div>
            <a href={homeLink}>
              <img
                src={logoURL || MHALogo}
                className="w-11 md:w-auto max-w-md lg:mr-12"
                alt="mha-logo"
              />
            </a>
            <HeaderMenu
              isOpen={isMobileMenuOpen}
              openedItemKey={openedItemKey}
              expandableItems={expandableItems}
              menuItemCategories={menuItemCategories}
              linkItems={linkItems}
              onItemClick={toggleItem}
              contentLinks={contentLinks}
              languageSwitcher={
                <MobileLanguageSwitcher
                  className="md:hidden"
                  locale={locale}
                  localeURLs={localeURLs}
                />
              }
            />
          </div>
          <div className="flex items-center">
            <LanguageSwitcher
              className="hidden md:block"
              locale={locale}
              localeURLs={localeURLs}
            />
            <HeaderUserMenu userMenu={userMenu} />
          </div>
        </nav>
      </div>
      <div className="hidden md:block">
        {expandableItems.map((key) => (
          <HeaderMenuCategories
            key={key}
            menuKey={key}
            isOpen={key === openedItemKey}
            categories={menuItemCategories[key]}
            contentLink={contentLinks[key]}
          />
        ))}
      </div>
    </div>
  );
};

Header.propTypes = {
  logoURL: PropTypes.string,
  menuItemCategories: PropTypes.object,
  contentLinks: PropTypes.object,
  linkItems: PropTypes.array,
  locale: PropTypes.oneOf(["en", "th"]),
  localeURLs: PropTypes.object,
  userMenu: PropTypes.object,
  homeLink: PropTypes.string,
};

export default Header;
