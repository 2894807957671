import { useState, useRef, useEffect } from "react";
import { isCancel, CancelToken } from "axios";

export default function usePagination({
  onLoadMore,
  perPage = 6,
  initialContent = [],
}) {
  const cancelTokenRef = useRef();
  const [isLastPage, setIsLastPage] = useState(false);
  const [content, setContent] = useState(initialContent);
  const [isLoading, setIsLoading] = useState(false);
  const nextPageRef = useRef(2);

  const loadMore = async () => {
    setIsLoading(true);
    try {
      const { content: newContent, pageCount } = await onLoadMore({
        page: nextPageRef.current,
        perPage,
      });
      setIsLastPage(nextPageRef.current >= Number(pageCount));
      setContent([...content, ...newContent]);
      nextPageRef.current += 1;
    } finally {
      setIsLoading(false);
    }
  };

  const initializedRef = useRef(false);
  // Reacts to changes of `onLoadMore` functioin
  // Re-fetch content and reset pagination states
  useEffect(() => {
    if (!initializedRef.current) {
      initializedRef.current = true;
      return;
    }

    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel();
    }

    cancelTokenRef.current = CancelToken.source();
    setIsLoading(true);
    onLoadMore(
      { page: 1, perPage },
      { cancelToken: cancelTokenRef.current.token }
    )
      .then(({ content: newContent, pageCount }) => {
        setContent(newContent);
        setIsLoading(false);
        setIsLastPage(Number(pageCount) <= 1);
        nextPageRef.current = 2;
      })
      .catch((e) => {
        if (!isCancel(e)) {
          throw e;
        }
      });
  }, [perPage, onLoadMore]);

  const reset = (newContent) => {
    nextPageRef.current = 2;
    cancelTokenRef.current = undefined;
    setIsLastPage(false);
    setContent(newContent);
  };

  return {
    content,
    isLoading,
    isLastPage,
    loadMore,
    reset,
  };
}
