import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { locales, getLabel } from "utils/locale";

const MobileLanguageSwitcher = ({ className, locale, localeURLs }) => {
  return (
    <div
      className={classNames(
        "lg:hidden mt-1 container mx-auto text-xs",
        className
      )}
    >
      <div className="text-grey-300 mb-3">Language</div>
      {locales.map(({ value }) => (
        <a
          key={value}
          className={classNames(
            "btn mr-7 last:mr-0",
            value === locale && "active font-medium"
          )}
          href={localeURLs[value]}
        >
          {getLabel(value)}
        </a>
      ))}
    </div>
  );
};

MobileLanguageSwitcher.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.oneOf(["en", "th"]),
  localeURLs: PropTypes.object,
};

export default MobileLanguageSwitcher;
