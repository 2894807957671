import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { locale, t } from "i18n-js";
import ContentListing from "components/common/ContentListing";
import api from "api";
import ContentListingCategories from "components/common/ContentListingCategories";
import EmptyContent from "components/common/EmptyContent";
import MyCourseCard from "./MyCourseCard";
import EmptyContentIcon from "../../images/icons/empty-content.svg?inline";

function sortStatus(data) {
  const addedPeople = data.filter((course) => course.status === "added");
  const inProgressPeople = data.filter(
    (course) => course.status === "in_progress"
  );
  const takenTestPeople = data.filter(
    (course) => course.status === "take_test"
  );
  const completed = data.filter((course) => course.status === "completed");

  return [
    ...addedPeople,
    ...inProgressPeople,
    ...takenTestPeople,
    ...completed,
  ];
}

async function fetchCourses({ page, perPage, category }) {
  const response = await api.get("/my_courses", {
    params: { page, per_page: perPage, category },
  });

  return {
    content: sortStatus(response.data),
    pageCount: response.headers["page-count"],
  };
}

const MyCourseListing = ({
  categories = [],
  courses = [],
  onLoadMore = fetchCourses,
}) => {
  const [category, setCategory] = useState("all");
  const fetch = useCallback(
    ({ page, perPage }) => {
      return onLoadMore({ page, perPage, category });
    },
    [onLoadMore, category]
  );
  const sortedCourses = sortStatus(courses);

  return (
    <>
      <ContentListingCategories
        currentSlug={category}
        categories={categories}
        onCategoryChange={(newCategory) => {
          setCategory(newCategory);
        }}
        showAll={false}
      />
      <ContentListing
        categories={categories}
        itemComponent={MyCourseCard}
        initialContent={sortedCourses}
        onLoadMore={fetch}
        emptyContent={
          <EmptyContent
            title={t("my_courses.empty_content.title")}
            buttonText={t("my_courses.empty_content.link_text")}
            continueURL={`/${locale}/courses`}
            iconComponent={<EmptyContentIcon className="mx-auto" />}
          />
        }
      />
    </>
  );
};

MyCourseListing.propTypes = {
  courses: PropTypes.array,
  categories: PropTypes.array,
  onLoadMore: PropTypes.func,
};

export default MyCourseListing;
