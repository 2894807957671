export function getAuthenticityToken() {
  if (typeof global.FORM_AUTHENTICITY_TOKEN !== "undefined") {
    return global.FORM_AUTHENTICITY_TOKEN;
  }
  if (typeof document !== "undefined") {
    return document.querySelector('meta[name="csrf-token"]')?.content || "";
  }

  return "";
}
