/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import StarIcon from "../../images/icons/star.svg?inline";

const MAX_RATING = 5;

const RatingDisplay = ({ rating }) => {
  return (
    <div className="flex">
      <span className="text-brand-300 text-body mr-3">{rating}</span>
      {Array(MAX_RATING)
        .fill()
        .map((_, i) => (
          <StarIcon
            key={i}
            className={classNames(
              "fill-current w-7",
              i + 1 <= rating ? "text-brand-300" : "text-grey-200"
            )}
          />
        ))}
    </div>
  );
};

RatingDisplay.propTypes = {
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default RatingDisplay;
