/* eslint-disable react/no-danger */
import React, { useState } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import AccountExpiredModal from "components/common/AccountExpiredModal";
import api from "api";
import CourseDetailSpeaker from "./CourseDetailSpeaker";
import CourseMetadata from "./CourseMetadata";
import AddCourseModal from "./AddCourseModal";
import TakeTestModal from "./TakeTestModal";

const CourseLessons = loadable(() => import("./CourseLessons"));

async function updateCourseProgress(slug) {
  await api.post(`/courses/progresses`, { id: slug });
}
const CourseContent = ({
  slug,
  status,
  speakerName,
  speakerImageURL,
  speakerDetail,
  trailerURL,
  lessons,
  examCount = 0,
  progress,
  allLessonFinished,
}) => {
  const shoulShowdAddCourseModal =
    status === "not_logged_in" || status === "not_added";
  // TODO: Add button

  const [isAddCourseModalOpen, setIsAddCourseModalOpen] = useState(false);
  const [takeTestModalOpen, setTakeTestModalOpen] = useState(false);
  const shouldShowAccountExpiredModal = status === "trial_expired";
  const [isAccountExpiredModalOpen, setIsAccountExpiredModalOpen] = useState(
    false
  );

  return (
    <div className="flex flex-col md:flex-col-reverse md:bg-grey-100 relative">
      <div className="container mx-auto my-7 md:max-w-none md:p-0 md:bg-white md:my-0">
        <div className="border border-grey-150 px-4 divide-y divide-grey-150 md:divide-y-0 md:divide-x md:flex md:items-center md:border-0 md:container md:mx-auto md:border-b">
          <div className="md:w-8/12 md:pr-7">
            <CourseDetailSpeaker
              speakerName={speakerName}
              speakerImageURL={speakerImageURL}
              speakerDetail={speakerDetail}
            />
          </div>
          <div className="md:w-4/12 md:pl-8">
            <CourseMetadata lessons={lessons} examCount={examCount} />
          </div>
        </div>
      </div>
      <CourseLessons
        trailerURL={trailerURL}
        lessons={lessons}
        courseStatus={status}
        disabled={shoulShowdAddCourseModal || shouldShowAccountExpiredModal}
        onOverlayClick={() => {
          if (shoulShowdAddCourseModal) {
            setIsAddCourseModalOpen(true);
          } else if (shouldShowAccountExpiredModal) {
            setIsAccountExpiredModalOpen(true);
          }
        }}
        progress={progress}
        allLessonFinished={allLessonFinished}
        examCount={examCount}
        onComplete={() => {
          if (examCount === 0) {
            window.location.href = `${slug}/ratings/new`;
          } else if (!allLessonFinished) {
            setTakeTestModalOpen(true);
          }
          if (progress.length === 0 || progress.status === null) {
            updateCourseProgress(slug);
          }
        }}
      />
      {takeTestModalOpen && (
        <TakeTestModal
          isOpen={takeTestModalOpen}
          onClose={() => {
            window.location.href = "";
          }}
          progress={progress}
        />
      )}
      {shoulShowdAddCourseModal && (
        <AddCourseModal
          courseSlug={slug}
          isOpen={isAddCourseModalOpen}
          onClose={() => {
            setIsAddCourseModalOpen(false);
          }}
          requireLogin={status === "not_logged_in"}
        />
      )}
      {shouldShowAccountExpiredModal && (
        <AccountExpiredModal
          isOpen={isAccountExpiredModalOpen}
          onClose={() => {
            setIsAccountExpiredModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

CourseContent.propTypes = {
  slug: PropTypes.string,
  status: PropTypes.string,
  speakerName: PropTypes.string,
  speakerImageURL: PropTypes.string,
  speakerDetail: PropTypes.string,
  trailerURL: PropTypes.string,
  lessons: PropTypes.array,
  examCount: PropTypes.number,
  progress: PropTypes.array,
  allLessonFinished: PropTypes.bool,
};

export default CourseContent;
