import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import Collapsible from "react-collapsible";
import ChevronDownIcon from "../../images/icons/chevron-down.svg?inline";

const CourseDetailSpeaker = ({
  speakerName,
  speakerImageURL,
  speakerDetail,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="py-4">
      <Collapsible
        open={isOpen}
        onOpening={() => {
          setIsOpen(true);
        }}
        onClosing={() => {
          setIsOpen(false);
        }}
        trigger={
          <div className="flex items-center font-medium text-xs md:items-center">
            <img
              className="rounded-full w-8 h-8 object-cover md:w-13 md:h-13"
              src={speakerImageURL}
              alt={speakerName}
            />
            <div className="ml-1 md:ml-7">
              <span className="mr-1 text-grey-300">
                {t("courses.show.speaker")}
              </span>
              {speakerName}
              <div className="hidden md:block font-light">{speakerDetail}</div>
            </div>
            <ChevronDownIcon
              className={classNames(
                "ml-auto fill-current text-brand-300 transform md:hidden",
                isOpen ? "rotate-180" : "rotate-0"
              )}
            />
          </div>
        }
      >
        <div className="pt-6 text-xs md:hidden">{speakerDetail}</div>
      </Collapsible>
    </div>
  );
};

CourseDetailSpeaker.propTypes = {
  speakerName: PropTypes.string,
  speakerImageURL: PropTypes.string,
  speakerDetail: PropTypes.string,
};

export default CourseDetailSpeaker;
