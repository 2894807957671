import React, { useState } from "react";
import { getAuthenticityToken } from "utils/getAuthenticityToken";
import { t } from "i18n-js";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";

const TrialLoginForm = ({ defaultPhoneNumber, locale, link }) => {
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);

  return (
    <form
      action={`/${locale}/trial_users/session`}
      acceptCharset="UTF-8"
      method="post"
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={getAuthenticityToken()}
      />
      <div className="mt-4 mb-9 text-grey-300">
        {t("trial_account.description")}
      </div>
      <div>
        <label
          className="text-grey-300 font-semibold text-xs"
          htmlFor="trial_users_authentication_new_session_form_phone_number"
        >
          {t("trial_account.phone_number.label")}
        </label>
        <PhoneInput
          placeholder={t(`login_form_component.phone_number.ph`)}
          value={phoneNumber}
          onChange={setPhoneNumber}
          country="th"
          preferredCountries={["th"]}
          disableCountryCode
          inputClass="text-grey border border-grey-200 p-1 block w-full mb-8"
          inputProps={{
            name: "trial_users_authentication_new_session_form[phone_number]",
            autoFocus: true,
          }}
        />
        <input
          type="hidden"
          name="trial_users_authentication_new_session_form[trial_account]"
          value
        />
      </div>
      <div className="actions">
        <input
          type="submit"
          name="commit"
          value={t("trial_account.submit")}
          className="btn bg-primary border-0 font-semibold text-white hover:text-white cursor-auto cursor-pointer px-4 py-2 block mx-auto"
        />
      </div>
      <div className="text-center mt-12">
        <div className="text-sm inline">
          {t("trial_account.already_have_id")}
        </div>
        <div className="text-sm inline text-brand-300 font-semibold">
          <a className="text-brand-300 pl-2" href={link.user_account}>
            {t("trial_account.sign_in")}
          </a>
        </div>
      </div>
    </form>
  );
};

TrialLoginForm.propTypes = {
  defaultPhoneNumber: PropTypes.string,
  locale: PropTypes.string,
  link: PropTypes.string,
};

export default TrialLoginForm;
