import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactModal from "react-modal";
import AddCourseImage from "images/courses/add-course.png";
import SuccessImage from "images/success.png";
import { t, locale } from "i18n-js";
import api from "api";
import ChevronDownIcon from "../../images/icons/chevron-down.svg?inline";
import PlusIcon from "../../images/icons/plus.svg?inline";

async function addToMyCoures(courseSlug) {
  await api.post("/my_courses", { slug: courseSlug });
}

const AddCourseModal = ({ courseSlug, isOpen, onClose, requireLogin }) => {
  const [isAdded, setIsAdded] = useState(false);
  const handleCourseAdd = async () => {
    await addToMyCoures(courseSlug);
    setIsAdded(true);
  };

  let content;
  if (isAdded) {
    content = (
      <>
        <p className="text-body px-7">
          {t("courses.show.add_course.success_detail")}
        </p>
        <button
          className="btn inline-flex items-center font-medium mt-11"
          type="button"
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("courses.show.add_course.start_learning")}
          <ChevronDownIcon className="ml-4 transform -rotate-90 fill-current text-brand-300" />
        </button>
      </>
    );
  } else if (requireLogin) {
    content = (
      <>
        <p className="text-body px-7">{t("courses.show.sign_in.subtitle")}</p>
        <a
          className="btn bg-brand-300 text-white font-medium my-7"
          href={`/${locale}/users/session/new`}
        >
          {t("header.session.sign_in")}
        </a>
        <br />
        {/*{t("courses.show.sign_in.or")}
         <br />
        <a
          className="text-brand-300 mt-1 inline-block"
          href={`/${locale}/trial_users/session/new`}
        >
          {t("courses.show.sign_in.free_trial")}
        </a> */}
      </>
    );
  } else {
    content = (
      <>
        <p className="text-body px-7">
          {t("courses.show.add_course.subtitle")}
        </p>
        <button
          className="btn bg-brand-300 text-white font-medium my-7 inline-flex items-center"
          type="button"
          onClick={handleCourseAdd}
        >
          <PlusIcon className="mr-2" />
          {t("courses.show.add_course.add_to_my_course")}
        </button>
      </>
    );
  }

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        if (isAdded) {
          window.location.reload();
          return;
        }
        onClose();
      }}
      overlayClassName="fixed z-50 inset-0 bg-grey-400 bg-opacity-70 flex items-center justify-center"
      className="h-auto focus:outline-none w-full max-w-mobile-course-detail-modal md:max-w-screen-sm"
    >
      <div className="bg-white text-center pt-10 pb-8">
        <img
          src={AddCourseImage}
          alt="Add course"
          className={classNames(
            "w-add-course-image inline-block",
            isAdded && "hidden"
          )}
        />
        <img
          src={SuccessImage}
          alt="Success"
          className={classNames(
            "w-add-course-image inline-block",
            isAdded ? "block" : "hidden"
          )}
        />
        {isAdded ? (
          <h3 className="font-medium mt-3 text-success-200">
            {t("courses.show.add_course.success")}
          </h3>
        ) : (
          <h3 className="font-medium mt-3">
            {t("courses.show.add_course.title")}
          </h3>
        )}
        {content}
      </div>
    </ReactModal>
  );
};

AddCourseModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  courseSlug: PropTypes.string,
  requireLogin: PropTypes.bool,
};

export default AddCourseModal;
