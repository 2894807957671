import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getSelectedFilterFieldValues, toggleFieldFilters } from "utils/recipe";
import { t } from "i18n-js";
import FilterIcon from "../../images/icons/filter.svg?inline";
import CloseIcon from "../../images/icons/close.svg?inline";

const RecipeFilterBadges = ({
  filters = {},
  selectedFilters = {},
  setSelectedFilters,
}) => {
  const values = getSelectedFilterFieldValues(selectedFilters);

  return (
    <div
      className={classNames(
        "py-7 border-b border-grey-150 mb-7 items-baseline",
        values.length ? "md:flex" : "md:hidden"
      )}
    >
      <FilterIcon className="hidden md:block w-6 h-6 mr-5 flex-none" />
      <div className="-my-1 flex-1">
        {values.map(([field, value]) => (
          <div
            className="px-2 leading-7 bg-brand-025 text-brand-300 text-xs inline-block whitespace-no-wrap mr-4 my-1"
            key={`${field}.${value}`}
          >
            {filters[field].find((f) => f.value === value)?.label || value}
            <button
              className="inline align-middle ml-1"
              type="button"
              onClick={() => {
                setSelectedFilters(
                  toggleFieldFilters(field, value, selectedFilters)
                );
              }}
            >
              <CloseIcon className="w-2" />
            </button>
          </div>
        ))}
      </div>
      <button
        className="hidden md:block btn font-medium text-xs"
        type="button"
        onClick={() => {
          setSelectedFilters({});
        }}
      >
        {t("recipes.filter.clear_all")}
      </button>
    </div>
  );
};

RecipeFilterBadges.propTypes = {
  filters: PropTypes.object,
  selectedFilters: PropTypes.object,
  setSelectedFilters: PropTypes.func.isRequired,
};

export default RecipeFilterBadges;
