import React from "react";
import PropTypes from "prop-types";
import { t, locale } from "i18n-js";
import CourseRate from "./CourseRate";
import TestPass from "../../images/icons/test-pass.svg?inline";
import TestFail from "../../images/icons/test-fail.svg?inline";
import Download from "../../images/icons/download.svg?inline";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";
import ThankYou from "../../images/success.png";

const ExamStatus = ({ initialContent = [], status, downloadUrl }) => {
  const {
    totalCorrectAnswers,
    totalQuestion,
    examLink,
    courseId,
    defaultHasRated,
    defaultHasFeedback,
  } = initialContent;
  const renderSuccess = () => {
    return (
      <div className="container">
        <div className="mx-auto mb-8">
          {defaultHasFeedback ? (
            <img
              src={ThankYou}
              alt="thank_you"
              width="102"
              height="102"
              className="m-auto"
            />
          ) : (
            <TestPass className="transform inline-block fill-current" />
          )}
        </div>
        <h2 className="text text-success-200 font-semibold">
          {defaultHasFeedback
            ? t("exam.index.thanks_for_feedback")
            : t("exam.index.congratulations")}
        </h2>
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{
            __html: t("exam.index.congratulations_text", {
              total: `${totalCorrectAnswers}/${totalQuestion}`,
            }),
          }}
        />
        <div className="my-9">
          {defaultHasFeedback ? (
            <a
              href={`/${locale}/courses`}
              className="py-2 px-4 border border-grey-200"
            >
              <ChevronDown className="inline-block transform rotate-90 fill-current text-primary" />
              <span className="ml-3 font-semibold">
                {t("exam.index.back_to_courses")}
              </span>
            </a>
          ) : (
            <a
              href={downloadUrl}
              download={initialContent.fileName}
              className="py-2 px-4 border border-grey-200"
            >
              <Download className="inline-block" />
              <span className="ml-3 font-semibold">
                {t("exam.index.download")}
              </span>
            </a>
          )}
        </div>

        {defaultHasFeedback ? null : (
          <CourseRate courseId={courseId} defaultHasRated={defaultHasRated} />
        )}
      </div>
    );
  };

  const renderFailed = () => {
    return (
      <div>
        <div className="mx-auto mb-8">
          <TestFail className="transform inline-block fill-current" />
        </div>
        <h2 className="text text-grey-300 font-semibold">
          {t("exam.index.sorry")}
        </h2>
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{
            __html: t("exam.index.failed_text", {
              total: `${totalCorrectAnswers}/${totalQuestion}`,
            }),
          }}
        />
        <div className="my-9">
          <a href={examLink} className="py-2 px-4 border border-grey-200">
            <span className="font-semibold">{t("exam.index.retake")}</span>
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="text-center">
      {status === "failed" ? renderFailed() : renderSuccess()}
    </div>
  );
};

ExamStatus.propTypes = {
  initialContent: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.string,
  downloadUrl: PropTypes.string,
};

export default ExamStatus;
