import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import WarningImage from "images/warning.png";
import { locale, t } from "i18n-js";

const AccountExpiredModal = ({ isOpen, onClose }) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
      overlayClassName="fixed z-50 inset-0 bg-grey-400 bg-opacity-70 flex items-center justify-center"
      className="h-auto focus:outline-none w-full max-w-mobile-course-detail-modal md:max-w-screen-sm"
    >
      <div className="bg-white text-center pt-10 pb-8">
        <img
          src={WarningImage}
          alt="warning"
          className="w-add-course-image inline-block"
        />
        <h3 className="font-medium mt-3 text-grey-300">
          {t("courses.show.account_expired.title")}
        </h3>
        <p className="font-light text-body px-7">
          {t("courses.show.account_expired.subtitle")}
        </p>
        <a
          href={`/${locale}/users/session/new`}
          className="btn bg-brand-300 text-white font-medium"
        >
          {t("shared.become_member")}
        </a>
        <div className="text-sm mt-10">
          {t("shared.continue_home.continue")}
          <a href="/" className="text-brand-300 font-semibold">
            {" "}
            {t("shared.continue_home.home")}
          </a>
        </div>
      </div>
    </ReactModal>
  );
};

AccountExpiredModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default AccountExpiredModal;
