import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { serializeFilters, parseFilterParams } from "utils/recipe";
import FilterIcon from "../../images/icons/filter.svg?inline";
import RecipeSortMenu from "./RecipeSortMenu";
import RecipeFilterMobileMenu from "./RecipeFilterMobileMenu";
import RecipeFilterDesktopMenu from "./RecipeFilterDesktopMenu";
import RecipeFilterBadges from "./RecipeFilterBadges";

const FILTER_MENU = {
  FILTER: "FILTER",
  SORT: "SORT",
};

const RecipeFilters = ({
  buttonId,
  filtersId,
  filters = {},
  defaultSelectedFilters = {},
  defaultSortBy = "latest",
  onFilterChange,
  onSortingChange,
  shouldUpdateURLParams = false,
}) => {
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [filterMenu, setFilterMenu] = useState("");
  const closeFilterMenu = () => {
    setFilterMenu("");
  };

  const [selectedFilters, setSelectedFilters] = useState({
    defaultSelectedFilters,
  });

  useEffect(() => {
    if (window.location.search.includes("filters[")) {
      setSelectedFilters(parseFilterParams(window.location.search));
      setIsMobileActive(true);
    }
  }, []);

  useEffect(() => {
    if (shouldUpdateURLParams) {
      window.history.replaceState(
        { filters: selectedFilters },
        "filter",
        `?${serializeFilters(selectedFilters)}`
      );
    }
    onFilterChange(selectedFilters);
  }, [shouldUpdateURLParams, selectedFilters, onFilterChange]);

  const [sortBy, setSortBy] = useState(defaultSortBy);
  useEffect(() => {
    onSortingChange(sortBy);
  }, [sortBy, onSortingChange]);

  return (
    <>
      {ReactDOM.createPortal(
        <button
          className="btn w-10 h-10 md:hidden"
          type="button"
          onClick={() => {
            setIsMobileActive(true);
            setTimeout(() => {
              setFilterMenu(FILTER_MENU.FILTER);
            });
          }}
        >
          <FilterIcon className="mx-auto" />
        </button>,
        document.getElementById(buttonId)
      )}
      {ReactDOM.createPortal(
        <div
          className={classNames(
            "md:block md:mb-9",
            isMobileActive ? "block" : "hidden"
          )}
        >
          <div className="flex border border-grey-150 divide-x divide-grey-150 md:container md:mx-auto md:grid md:grid-cols-12 md:gap-x-7 md:divide-x-0 md:border-0">
            <div className="w-1/2 md:hidden">
              <RecipeFilterMobileMenu
                filters={filters}
                selectedFilters={selectedFilters}
                setSelectedFilters={(newFilters) => {
                  setSelectedFilters(newFilters);
                }}
                isOpen={filterMenu === FILTER_MENU.FILTER}
                onOpen={() => {
                  setFilterMenu(FILTER_MENU.FILTER);
                }}
                onClose={closeFilterMenu}
              />
            </div>
            {Object.entries(filters).map(([field, options]) => (
              <div className="hidden md:block col-span-3" key={field}>
                <RecipeFilterDesktopMenu
                  field={field}
                  options={options}
                  isOpen={filterMenu === field}
                  onOpen={() => {
                    setFilterMenu(field);
                  }}
                  onClose={closeFilterMenu}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              </div>
            ))}
            <div className="w-1/2 md:w-auto md:col-span-3">
              <RecipeSortMenu
                sortBy={sortBy}
                isOpen={filterMenu === FILTER_MENU.SORT}
                onOpen={() => {
                  setFilterMenu(FILTER_MENU.SORT);
                }}
                onClose={closeFilterMenu}
                onSortingClick={setSortBy}
              />
            </div>
          </div>
          <div className="container mx-auto">
            <RecipeFilterBadges
              filters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          </div>
        </div>,
        document.getElementById(filtersId)
      )}
    </>
  );
};

RecipeFilters.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  buttonId: PropTypes.string.isRequired,
  filtersId: PropTypes.string.isRequired,
  filters: PropTypes.object,
  defaultSelectedFilters: PropTypes.object,
  defaultSortBy: PropTypes.oneOf(["latest", "rating", "name:asc", "name:desc"]),
  shouldUpdateURLParams: PropTypes.bool,
};

export default RecipeFilters;
