import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import StarIcon from "../../images/icons/star.svg?inline";

const RecipeCard = ({
  className,
  title,
  thumbnailURL,
  rating,
  cookingTime,
  url,
}) => {
  return (
    <a
      className={classNames(
        "block group bg-grey-050 transition-all duration-75 hover:shadow-blur-40 hover:bg-white w-full max-w-sm",
        className
      )}
      href={url}
    >
      <div
        className="pt-16/9-thumb bg-center bg-contain bg-no-repeat"
        style={{ backgroundImage: `url(${thumbnailURL})` }}
      />
      <div className="p-4 flex flex-col justify-between md:h-recipe-card-content group-hover:border-b-2 border-brand-300">
        <div className="mt-3 font-semibold text-body truncate-2-lines">
          {title}
        </div>
        <div className="flex mt-4">
          {(typeof rating === "string" || typeof rating === "number") && (
            <>
              <span className="text-primary">
                {Math.round(rating).toFixed(1)}
              </span>
              <StarIcon className="inline-block w-4 ml-1 mr-4" />
            </>
          )}
          <div className="font-light text-xs text-grey-300">
            {t("recipes.cooking_time", { m: cookingTime })}
          </div>
        </div>
      </div>
    </a>
  );
};

RecipeCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cookingTime: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

export default RecipeCard;
