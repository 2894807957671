/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import api from "api";
import { t } from "i18n-js";
import Rate from "../common/Rate";

async function sendRecipeRating(recipeId, rating) {
  await api.post("/recipe_ratings", { rate: rating, recipe_id: recipeId });
}

const RecipeRate = ({
  recipeId,
  onRate,
  redirect,
  defaultHasRated = false,
}) => {
  return (
    <div className="rate flex md:inline-flex flex-wrap items-center justify-center">
      <Rate
        id={recipeId}
        onRate={onRate}
        redirect={redirect}
        defaultHasRated={defaultHasRated}
        postRating={sendRecipeRating}
        rateTitle={t("recipes.show.rate.title")}
      />
    </div>
  );
};

RecipeRate.propTypes = {
  recipeId: PropTypes.number,
  onRate: PropTypes.func,
  defaultHasRated: PropTypes.bool,
  redirect: PropTypes.string,
};

export default RecipeRate;
