import React from "react";
import PropTypes from "prop-types";
import Carousel from "components/common/Carousel";
import CarouselControl from "components/common/CarouselControl";
import "./BannerCarousel.scss";

const BannerCarousel = ({ banners = [] }) => {
  return (
    <Carousel
      customButtonGroup={
        <CarouselControl
          wrapperClassName="relative mt-7 md:mt-0"
          elementClassName="md:absolute bottom-0 right-6 md:w-7/12"
        />
      }
    >
      {banners.map(
        ({ id, title, subtitle, thumbnailURL, buttonLink, buttonText }) => (
          <>
            <div className="hidden md:block absolute bg-brand-300 pt-13 w-full h-full" />
            <div className="hidden md:block absolute white-bg-position bg-white w-full h-full" />

            <div
              className="flex relative mobile-banner-image-carousel md:h-banner-image-carousel md:mt-13 md:mb-13"
              key={id}
            >
              <div className="flex flex-col-reverse md:block md:absolute banner-position h-mobile-banner-carousel md:h-auto md:bg-transparent bg-brand-300">
                <div>
                  <img
                    src={thumbnailURL}
                    alt={title}
                    className="h-mobile-banner-image-carousel md:h-banner-image-carousel w-screen object-cover md:h-banner-image-carousel"
                  />
                </div>
                <div className="pt-6 px-6 md:p-0 absolute w-full md:w-banner-content top-0 md:top-auto md:bottom-8 md:left-8">
                  <div className="p-7 md:p-8 bg-white h-mobile-banner-content-carousel md:h-auto">
                    <h2 className="mb-4 font-semibold truncate-2-lines md:truncate-3-lines">
                      {title}
                    </h2>
                    <div className="text text-grey-300 mb-7 truncate-2-lines md:truncate-3-lines">
                      {subtitle}
                    </div>
                    <a
                      href={buttonLink}
                      className="btn px-4 py-2 text-white font-semibold bg-brand-300"
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </Carousel>
  );
};

BannerCarousel.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      thumbnailURL: PropTypes.string.isRequired,
      buttonLink: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
    })
  ),
};

export default BannerCarousel;
