import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FlipMove from "react-flip-move";
import { t } from "i18n-js";
import usePagination from "hooks/usePagination";

const ContentListing = ({
  itemComponent,
  onLoadMore,
  perPage = 6,
  initialContent = [],
  emptyContent,
  itemClassName = "col-span-12 sm:col-span-6 md:col-span-4",
}) => {
  const { content, isLoading, isLastPage, loadMore } = usePagination({
    onLoadMore,
    perPage,
    initialContent,
  });
  const Item = itemComponent;

  return (
    <>
      {emptyContent && !isLoading && !content.length && (
        <div className="container mx-auto">{emptyContent}</div>
      )}
      <FlipMove
        className="container mx-auto grid grid-cols-12 gap-x-7 gap-y-7 md:gap-y-10"
        staggerDelayBy={100}
      >
        {content.map((contentProps) => (
          <div key={contentProps.slug} className={itemClassName}>
            <Item {...contentProps} />
          </div>
        ))}
      </FlipMove>
      {!isLastPage && (
        <button
          className={classNames(
            "btn block mx-auto text-brand-300 font-semibold mt-7 md:mt-10",
            isLoading && "animate-pulse"
          )}
          onClick={loadMore}
          type="button"
          disabled={isLoading}
        >
          {t("views.pagination.load_more")}
        </button>
      )}
    </>
  );
};

ContentListing.propTypes = {
  initialContent: PropTypes.arrayOf(PropTypes.object),
  itemComponent: PropTypes.elementType.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  perPage: PropTypes.number,
  itemClassName: PropTypes.string,
  emptyContent: PropTypes.node,
};

export default ContentListing;
