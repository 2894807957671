import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// import { t } from "i18n-js";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";

const MagazineCard = ({ className, name, volume, imageURL, url }) => {
  return (
    <a
      className={classNames(
        "block group bg-grey-050 transition-all duration-75 hover:shadow-blur-40 hover:bg-white max-w-sm mb-13 w-full",
        className
      )}
      href={url}
    >
      <div
        className="pt-paper-thumb bg-center bg-cover"
        style={{ backgroundImage: `url(${imageURL})` }}
      />
      <div className="p-4 flex flex-col justify-between group-hover:border-b-2 border-brand-300">
        <div className="m-6 font-semibold text-body truncate-3-lines h-13">
          {name}
        </div>
        <div className="flex justify-between content-center text-xs text-primary font-semibold m-6">
          <span className="uppercase">{volume}</span>
          <div className="font-medium text-brand-300">
            <ChevronDown className="ml-2 transform -rotate-90 inline-block fill-current text-primary" />
          </div>
        </div>
      </div>
    </a>
  );
};

MagazineCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  volume: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default MagazineCard;
