import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Transition } from "react-transition-group";
import { t } from "i18n-js";

import ChevronDown from "../images/icons/chevron-down.svg?inline";

const transitionClassNames = {
  entering: "opacity-1",
  entered: "opacity-1",
  exiting: "opacity-0",
  exited: "opacity-0 pointer-events-none",
};

const HeaderMenuCategories = ({
  menuKey,
  isOpen,
  categories = [],
  contentLink = "#",
}) => {
  const baseClassName =
    "absolute z-30 top-header inset-x-0 bg-white border-b border-brand-300 shadow-blur-40 transition-opacity duration-200";
  return (
    <Transition in={isOpen} timeout={200}>
      {(state) => (
        <div className={classNames(baseClassName, transitionClassNames[state])}>
          <div className="container mx-auto flex justify-between py-11">
            <div className="w-5/12">
              <h4 className="max-w-xs">{t(`${menuKey}.subtitle`)}</h4>
              <a className="btn mt-5 font-semibold text-sm" href={contentLink}>
                {t(`${menuKey}.view_all`)}
                <ChevronDown className="ml-4 inline-block transform -rotate-90 fill-current text-primary" />
              </a>
            </div>
            <div className="w-7/12">
              <div className="text-sm font-semibold border-l-2 border-brand-300 pl-4 mb-6 uppercase">
                {t("categories.prompt")}
              </div>
              <ul>
                {categories.map(({ slug, label, href }) => (
                  <li className="list-none inline-block my-1 mr-7 last:mr-0">
                    <a
                      key={slug}
                      className="btn py-1 px-4 text-xs font-medium"
                      href={href}
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

HeaderMenuCategories.propTypes = {
  menuKey: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  categories: PropTypes.array,
  contentLink: PropTypes.string,
};

export default HeaderMenuCategories;
