import axios from "axios";

export async function getVimeo(url) {
  try {
    const { data } = await axios.get("https://vimeo.com/api/oembed.json", {
      params: { url },
    });
    return data;
  } catch (e) {
    console.error(e);
    return {};
  }
}
