/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import api from "api";
import { toast } from "react-toastify";
import Rate from "../common/Rate";
import VerifiedIcon from "../../images/icons/verified.svg?inline";

async function sendCourseRating(courseId, rating) {
  await api.post("/course_ratings", { rate: rating, course_id: courseId });
}

const CourseRate = ({
  courseId,
  onRate,
  redirect,
  defaultHasRated = false,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const ref = useRef();

  async function sendCourseFeedback(feedback) {
    try {
      await api.post("/course_feedbacks", {
        feedback,
        course_id: courseId,
      });
      window.location.href = "";
    } catch (e) {
      console.error(e);
      toast.error(t("errors.messages.server_error"));
    }
  }

  const renderFeedBack = () => {
    return (
      <div>
        <div className="text text-success-200 border-b-1 border-grey-150 pb-8">
          <div className="flex justify-center items-center">
            <VerifiedIcon className="rate__verified mr-1" />
            <span className="text-success-200 font-medium">
              {t("course_ratings.thank_you")}
            </span>
          </div>
        </div>
        <div className="pt-8 border-t border-grey-150">
          <h5 className="text-left font-semibold mb-4">
            {t("course_ratings.title")}
          </h5>
          <input
            type="text"
            name="rating[feedback]"
            ref={ref}
            className="text-grey border border-grey-200 p-1 pr-10 block w-full mb-8"
          />
          <div className="text-center w-full md:text-right md:w-auto">
            <button
              type="button"
              onClick={() => sendCourseFeedback(ref.current.value)}
              className="w-full md:w-auto text-sm py-2 px-4 cursor-pointer bg-brand-300 text-white"
            >
              {t("course_ratings.submit")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="rate flex items-center justify-center">
      <div className="w-full p-8 border border-grey-150 text-center items-center">
        {showFeedback || defaultHasRated ? (
          renderFeedBack()
        ) : (
          <Rate
            id={courseId}
            onRate={onRate}
            redirect={redirect}
            feedback
            setShowFeedback={setShowFeedback}
            postRating={sendCourseRating}
            newLineStars
            rateTitle={t("exam.index.rate_title")}
          />
        )}
      </div>
    </div>
  );
};

CourseRate.propTypes = {
  courseId: PropTypes.number,
  onRate: PropTypes.func,
  defaultHasRated: PropTypes.bool,
  redirect: PropTypes.string,
};

export default CourseRate;
