import api from "api";

// Reference https://www.urbaninsight.com/article/tracking-progress-embedded-vimeo-videos
export class LessonProgressTracker {
  constructor(player, lessonId, onFinish) {
    this.player = player;
    this.lessonId = lessonId;
    this.progresses = Array(100);
    this.onFinish = onFinish;
    this.animationFrame = null;
  }

  init() {
    const update = async () => {
      const [currentTime, duration] = await Promise.all([
        this.player.getCurrentTime(),
        this.player.getDuration(),
      ]);
      const currentPercentage = Math.floor((currentTime * 100) / duration);
      this.progresses[currentPercentage] = true;
      this.animationFrame = window.requestAnimationFrame(update);
    };

    this.animationFrame = window.requestAnimationFrame(update);

    this.player.on("pause", (e) => {
      const currentPercent = Math.min(
        99,
        Math.max(Math.floor(e.percent * 100 - 1), 0)
      );
      this.progresses[currentPercent] = true;
    });

    this.player.on("ended", this.onEnded.bind(this));
    this.fetchProgressData();
  }

  destroy() {
    window.cancelAnimationFrame(this.animationFrame);
    this.player.off("play");
    this.player.off("pause");
    this.player.destroy();
    this.sendProgressData();
  }

  onEnded() {
    this.progresses.fill(true);
    this.progresses[100] = true;
    this.sendProgressData();
  }

  calculateTotalProgress() {
    return this.progresses.filter(Boolean).length;
  }

  mergeProgresses(newProgresses) {
    this.progresses = this.progresses.map(
      (value, i) => value || newProgresses[i] || null
    );
  }

  async sendProgressData() {
    const totalProgress = this.calculateTotalProgress();
    if (totalProgress > 0) {
      const progress = JSON.stringify(this.progresses);
      await api.post("/lesson_progresses", {
        progress,
        course_lesson_id: this.lessonId,
      });

      if (totalProgress >= 100) this.onFinish(this.lessonId);
    }
  }

  async fetchProgressData() {
    try {
      const { data } = await api.get(`/lesson_progresses/${this.lessonId}`);
      this.mergeProgresses(data.progress);
    } catch (e) {
      if (!e.response || e.response.status !== 404) {
        console.error(e);
      }
    }
  }
}
