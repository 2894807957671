import React from "react";
import PropTypes from "prop-types";

const Progress = ({ currentProgress, totalProgress }) => {
  const progressPercentage = (currentProgress / totalProgress) * 100;

  return (
    <>
      <div className="bg-grey-200 w-full h-progress-bar relative overflow-hidden rounded-xl">
        <span
          className="bg-brand-300 h-progress-bar absolute"
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
    </>
  );
};

Progress.propTypes = {
  currentProgress: PropTypes.number,
  totalProgress: PropTypes.number,
};

export default Progress;
