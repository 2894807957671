import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import LessonIcon from "../../images/icons/lessons.svg?inline";

const CourseCard = ({
  className,
  name,
  category,
  thumbnailURL,
  url,
  speakerImageURL,
  speakerName,
  lessonCount,
  // trailerURL,
}) => {
  return (
    <a
      className={classNames(
        "block group bg-grey-050 transition-all duration-75 hover:shadow-blur-40 hover:bg-white max-w-sm",
        className
      )}
      href={url}
    >
      <div
        className="pt-16/9-thumb bg-center bg-contain bg-no-repeat relative"
        style={{ backgroundImage: `url(${thumbnailURL})` }}
      >
        <div className="sm:hidden absolute bottom-0 inset-x-0 text-white px-4 py-2 bg-grey-400 bg-opacity-70">
          <LessonIcon className="fill-current inline mr-1" />
          {t("courses.show.lesson_count", { n: lessonCount })}
        </div>
      </div>
      <div className="p-4 h-mobile-article-card-content flex flex-col justify-between sm:h-article-card-content group-hover:border-b-2 border-brand-300">
        <div>
          <div className="uppercase border-l-2 transition-all duration-75 group-hover:border-0 border-brand-300 group-hover:pl-0 pl-1 text-grey-300 text-xs font-medium">
            {category}
          </div>
          <div className="mt-3 font-semibold text-body truncate-3-lines">
            {name}
          </div>
        </div>
        <div className="hidden sm:flex justify-between">
          <div className="inline-flex">
            <img
              src={speakerImageURL}
              className="rounded-full h-8 w-8"
              alt={speakerName}
            />
            <span className="text-xs font-semibold m-auto pl-1">
              {speakerName}
            </span>
          </div>

          <div className="text-xs font-semibold text-grey-300 mt-auto mb-auto">
            <LessonIcon className="inline-block w-4 ml-1 mr-4" />
            {t("courses.show.lesson_count", { n: lessonCount })}
          </div>
        </div>
      </div>
    </a>
  );
};

CourseCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  speakerImageURL: PropTypes.string,
  speakerName: PropTypes.string,
  lessonCount: PropTypes.number,
  // trailerURL: PropTypes.string,
};

export default CourseCard;
