export function clearFieldFilters(field, filters = {}) {
  const { [field]: _, ...newFilters } = filters;
  return newFilters;
}

export function toggleFieldFilters(field, value, filters = {}) {
  const { [field]: options = {}, ...restFilters } = filters;
  const { [value]: existingValue, ...newValues } = options;
  if (!existingValue) {
    newValues[value] = true;
  }

  if (Object.keys(newValues).length > 0) {
    return { ...restFilters, [field]: newValues };
  }

  return restFilters;
}

export function countSelectedFilters(filters = {}) {
  return Object.entries(filters).reduce(
    (count, [, fieldValues]) => count + Object.keys(fieldValues || {}).length,
    0
  );
}

export function serializeFilters(filters = {}) {
  return Object.entries(filters)
    .map(([field, values]) => {
      return Object.keys(values)
        .map((value) => `filters[${field}][]=${encodeURIComponent(value)}`)
        .join("&");
    })
    .join("&");
}

export function parseFilterParams(params) {
  const filters = {};
  params.split("&").forEach((fieldValue) => {
    const [lhs, value] = fieldValue.split("[]=");
    const fieldMatch = lhs.match(/\[([^\]]*)\]/);
    if (fieldMatch) {
      const field = fieldMatch[1];
      if (!field) {
        return;
      }

      if (!filters[field]) {
        filters[field] = {};
      }

      filters[field][value] = true;
    }
  });
  return filters;
}

export function getSelectedFilterFieldValues(selectedFilters = {}) {
  const selectedValues = [];
  Object.entries(selectedFilters).forEach(([field, values]) => {
    selectedValues.push(...Object.keys(values).map((value) => [field, value]));
  });
  return selectedValues;
}

export function countSelectedFieldOptions(selectedFilters = {}, field) {
  return Object.keys(selectedFilters[field] || {}).length;
}
