import React from "react";
import PropTypes from "prop-types";
import api from "api";
import ExamContent from "./ExamContent";

async function submitExam({ progressId, examId, answer, currentQuestion }) {
  const response = await api.post("/courses/answers", {
    progress_id: progressId,
    exam_id: examId,
    answer,
    current_question: currentQuestion,
  });
  return { progress: response.data };
}

const Exams = ({ progress = [] }) => {
  return (
    <>
      <ExamContent initialContent={progress} onNext={submitExam} />
    </>
  );
};

Exams.propTypes = {
  progress: PropTypes.array,
};

export default Exams;
