import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import ContentListing from "components/common/ContentListing";
import api from "api";
import ContentListingCategories from "components/common/ContentListingCategories";
import CourseCard from "./CourseCard";

async function fetchCourses({ page, perPage, category }) {
  const response = await api.get("/courses", {
    params: { page, per_page: perPage, category },
  });
  return { content: response.data, pageCount: response.headers["page-count"] };
}

const CourseListing = ({
  categories = [],
  courses = [],
  onLoadMore = fetchCourses,
}) => {
  const [category, setCategory] = useState("");
  const fetch = useCallback(
    ({ page, perPage }) => {
      return onLoadMore({ page, perPage, category });
    },
    [onLoadMore, category]
  );

  return (
    <>
      <ContentListingCategories
        currentSlug={category}
        categories={categories}
        onCategoryChange={(newCategory) => {
          setCategory(newCategory);
        }}
      />
      <ContentListing
        categories={categories}
        itemComponent={CourseCard}
        initialContent={courses}
        onLoadMore={fetch}
      />
    </>
  );
};

CourseListing.propTypes = {
  courses: PropTypes.array,
  categories: PropTypes.array,
  onLoadMore: PropTypes.func,
};

export default CourseListing;
