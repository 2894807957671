import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/format";
import Carousel from "components/common/Carousel";
import { t } from "i18n-js";
import CarouselControl from "components/common/CarouselControl";

const ArticleCarousel = ({ articles = [] }) => {
  return (
    <Carousel
      customButtonGroup={
        <CarouselControl
          wrapperClassName="mt-7 md:mt-4"
          elementClassName="md:w-7/12"
        />
      }
    >
      {articles.map(
        ({ slug, title, category, thumbnailURL, publishedAt, url }) => (
          <a
            key={slug}
            className="flex items-center flex-col-reverse md:flex-row md:container mx-auto"
            href={url}
          >
            <div className="container mt-4 md:mt-0 md:p-0 md:w-5/12">
              <span className="px-1 border border-grey-150 text-brand-300 bg-white text-xs font-medium">
                {t("shared.carousel.recommended")}
              </span>
              <h3 className="mt-6 font-medium pr-3">{title}</h3>
              <div className="mt-8 pl-2 uppercase font-medium text-xs text-grey-300 border-l-2 border-brand-300">
                {category}
              </div>
              <div className="mt-4 uppercase text-xs">
                {formatDate(publishedAt)}
              </div>
            </div>
            <img
              src={thumbnailURL}
              alt={title}
              className="h-mobile-carousel-thumb w-full object-cover sm:h-tablet-carosel-thumb md:h-auto md:w-7/12"
            />
          </a>
        )
      )}
    </Carousel>
  );
};

ArticleCarousel.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      thumbnailURL: PropTypes.string.isRequired,
      publishedAt: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      url: PropTypes.string.isRequired,
    })
  ),
};

export default ArticleCarousel;
