import React from "react";
import PropTypes from "prop-types";
import api from "api";
import ThisWeek from "./ThisWeek";
import Upcoming from "./Upcoming";
import ThisWeekCard from "./ThisWeekCard";
import UpcomingCard from "./UpcomingCard";
import MobileEventCard from "./MobileEventCard";

async function fetchUpcomingEvents({ page, perPage }) {
  const response = await api.get("/events/upcoming", {
    params: { page, per_page: perPage },
  });
  return { content: response.data, pageCount: response.headers["page-count"] };
}

const Events = ({
  thisWeekEvents = [],
  upcomingEvents = [],
  onLoadMore = fetchUpcomingEvents,
  isLastPage,
}) => {
  return (
    <>
      <ThisWeek
        itemComponent={ThisWeekCard}
        mobileItemComponent={MobileEventCard}
        initialContent={thisWeekEvents}
      />
      <Upcoming
        itemComponent={UpcomingCard}
        mobileItemComponent={MobileEventCard}
        initialContent={upcomingEvents}
        onLoadMore={onLoadMore}
        lastPage={isLastPage}
      />
    </>
  );
};

Events.propTypes = {
  thisWeekEvents: PropTypes.array,
  upcomingEvents: PropTypes.array,
  onLoadMore: PropTypes.func,
  isLastPage: PropTypes.bool,
};

export default Events;
