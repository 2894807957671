import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { t } from "i18n-js";
import Warning from "images/icons/warning.svg?inline";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";

const LeaveTestModal = ({ isOpen, onClose, leaveURL }) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="fixed z-50 inset-0 bg-grey-400 bg-opacity-70 flex items-center justify-center"
      className="h-auto focus:outline-none w-full max-w-mobile-course-detail-modal md:max-w-screen-sm"
    >
      <div className="bg-white text-center pt-10 pb-8">
        <Warning className="mx-auto" />
        <h3 className="font-medium mt-3 mb-4 text-success-200">
          {t("exam.index.leave_title")}
        </h3>
        <div className="mb-9">{t("exam.index.leave_description")}</div>
        <div className="mb-10">
          <button
            type="button"
            className="py-2 px-4 border border-1 border-grey-200"
            onClick={onClose}
          >
            {t("exam.index.continue")}
            <ChevronDown className="inline-block ml-2 fill-current text-primary transform -rotate-90" />
          </button>
        </div>
        <div className="text-sm">
          <a href={leaveURL} className="text-brand-300 font-semibold">
            {t("exam.index.leave_this_test")}
          </a>
        </div>
      </div>
    </ReactModal>
  );
};

LeaveTestModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  leaveURL: PropTypes.string,
};

export default LeaveTestModal;
