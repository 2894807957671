import React from "react";
import ReactPlayer from "react-player/vimeo";
import PropTypes from "prop-types";
import Carousel from "components/common/Carousel";
import { t } from "i18n-js";
import CarouselControl from "components/common/CarouselControl";
import LessonIcon from "../../images/icons/lessons.svg?inline";
import "./CourseCarousel.scss";

const CourseCarousel = ({ courses = [] }) => {
  return (
    <Carousel
      customButtonGroup={
        <CarouselControl
          wrapperClassName="mt-7 md:mt-4"
          elementClassName="md:w-7/12"
        />
      }
    >
      {courses.map(
        ({
          slug,
          name,
          category,
          thumbnailURL,
          speakerName,
          speakerImageURL,
          lessonCount,
          trailerURL,
          url,
        }) => (
          <a
            key={slug}
            className="flex items-center flex-col-reverse md:flex-row md:container mx-auto"
            href={url}
          >
            <div className="container mt-4 md:mt-0 md:pr-8 md:w-5/12">
              <span className="px-1 border border-grey-150 text-brand-300 bg-white text-xs font-medium">
                {t("shared.carousel.recommended")}
              </span>
              <h3 className="mt-6 font-medium pr-3">{name}</h3>
              <div className="mt-8 pl-2 uppercase font-medium text-xs text-grey-300 border-l-2 border-brand-300">
                {category}
              </div>
              <div className="flex justify-between mt-8 bg-grey-050 p-7">
                <div className="inline-flex">
                  <img
                    src={speakerImageURL}
                    className="rounded-full h-8 w-8"
                    alt={speakerName}
                  />
                  <span className="text-xs font-semibold m-auto pl-1">
                    {speakerName}
                  </span>
                </div>

                <div className="text-xs font-semibold text-grey-300 mt-auto mb-auto">
                  <LessonIcon className="inline-block w-4 ml-1 mr-4" />
                  {lessonCount} {t("courses.title")}
                </div>
              </div>
            </div>
            <div className="w-full md:w-7/12">
              {trailerURL ? (
                <ReactPlayer
                  className="react-player"
                  url={trailerURL}
                  config={{ vimeo: { playerOptions: { background: 1 } } }}
                  width="100%"
                  height="100%"
                />
              ) : (
                <div className="react-player">
                  <div className="w-full h-full">
                    <div
                      className="w-full h-full bg-center bg-contain bg-no-repeat"
                      style={{
                        backgroundImage: `url(${thumbnailURL})`,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </a>
        )
      )}
    </Carousel>
  );
};

CourseCarousel.propTypes = {
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      thumbnailURL: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default CourseCarousel;
