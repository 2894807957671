import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";

const baseButtonClassName =
  "btn h-10 w-10 inline-flex justify-center items-center text-brand-300 mr-9 last:mr-0 bg-white bg-opacity-75";

const CarouselControl = ({
  next,
  previous,
  carouselState,
  onClick,
  wrapperClassName,
  elementClassName,
  buttonClassName,
  cursorPosition,
  cursorBorder,
}) => {
  const highlightIndex =
    (carouselState.currentSlide / carouselState.totalItems) * 100;
  const highlightScale = 1 / carouselState.totalItems;

  const finalButtonClassName = classNames(baseButtonClassName, buttonClassName);

  return (
    <div className={classNames("container mx-auto flex", wrapperClassName)}>
      <div
        className={classNames(
          "w-full flex items-center justify-between ml-auto",
          elementClassName
        )}
      >
        <div
          className={
            cursorBorder ||
            "w-full relative md:w-9/12 border-t-2 border-grey-200"
          }
          aria-hidden="true"
        >
          <div
            className="transition-transform duration-200"
            style={{ transform: `translate3d(${highlightIndex}%, 0 ,0)` }}
          >
            <div
              className="absolute border-t-2 border-brand-300 w-full origin-left"
              style={{
                transform: `scale3d(${highlightScale}, 1, 1) translate3d(0, -100%, 0)`,
              }}
            />
          </div>
        </div>
        <div
          className={
            cursorPosition ||
            "absolute w-screen inset-x-0 top-mobile-carousel-control transform -translate-y-1/2 md:transform-none flex justify-between md:static md:w-auto whitespace-no-wrap md:ml-2"
          }
        >
          <button
            className={finalButtonClassName}
            type="button"
            onClick={() => {
              onClick();
              previous();
            }}
            disabled={carouselState.currentSlide === 0}
          >
            <ChevronDown className="transform rotate-90 fill-current" />
          </button>
          <button
            className={finalButtonClassName}
            type="button"
            onClick={() => {
              onClick();
              next();
            }}
            disabled={
              carouselState.currentSlide >= carouselState.totalItems - 1
            }
          >
            <ChevronDown className="transform -rotate-90 fill-current" />
          </button>
        </div>
      </div>
    </div>
  );
};

CarouselControl.propTypes = {
  next: PropTypes.func,
  previous: PropTypes.func,
  carouselState: PropTypes.object,
  onClick: PropTypes.func,
  wrapperClassName: PropTypes.string,
  elementClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  cursorPosition: PropTypes.string,
  cursorBorder: PropTypes.string,
};

export default CarouselControl;
