import React, { useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Modal = ({ isOpen, modalContent, onClose, containerClass }) => {
  const ref = useRef();
  useOnClickOutside(ref, onClose);

  return (
    <div
      className={classNames(
        "fixed w-full h-full top-0 left-0 flex items-center justify-center z-50",
        isOpen ? "opacity-1" : "opacity-0 pointer-events-none"
      )}
    >
      <div className="modal-overlay absolute w-full h-full bg-grey-400 opacity-70" />
      <div className={classNames("modal-container z-50", containerClass)}>
        <div
          className="modal-content h-full p-10 text-center mx-auto"
          ref={ref}
        >
          {modalContent}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalContent: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  containerClass: PropTypes.string,
};

export default Modal;
