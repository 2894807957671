import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import TestStartImage from "images/test-start.png";
import { t } from "i18n-js";

const TakeTestModal = ({ isOpen, onClose, progress }) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
      overlayClassName="fixed z-50 inset-0 bg-grey-400 bg-opacity-70 flex items-center justify-center"
      className="h-auto focus:outline-none w-full max-w-mobile-course-detail-modal md:max-w-screen-sm"
    >
      <div className="bg-white text-center pt-10 pb-8">
        <img
          src={TestStartImage}
          alt="warning"
          className="w-add-course-image inline-block"
        />
        <h3 className="font-medium mt-3">{t("courses.show.exam.title")}</h3>
        <p className="font-light text-body px-7">
          {t("courses.show.exam.description")}
        </p>
        <a
          href={progress.examLink}
          className="btn bg-brand-300 text-white font-medium my-10"
        >
          {t("courses.show.exam.take_test")}
        </a>
        <p>{t("courses.show.exam.or")}</p>
        <div className="text-sm">
          <button
            type="button"
            onClick={onClose}
            className="text-brand-300 font-semibold cursor-pointer"
          >
            {t("courses.show.exam.test_later")}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

TakeTestModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  progress: PropTypes.arrayOf(PropTypes.object),
};

export default TakeTestModal;
