import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useOnClickOutside from "hooks/useOnClickOutside";
import { getAuthenticityToken } from "utils/getAuthenticityToken";
import I18n from "i18n-js";
import ChevronDown from "../images/icons/chevron-down.svg?inline";
import UserIcon from "../images/icons/user.svg?inline";

const HeaderUserMenuUnAuthenticated = ({ userMenu }) => {
  return (
    <a
      className="text-xs md:text-sm btn text-brand-300 font-semibold"
      type="button"
      href={userMenu.authenticationLinks.signIn}
    >
      {I18n.t("header.session.sign_in")}
    </a>
  );
};

const HeaderUserMenuAuthenticated = ({ userMenu }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className="relative" ref={ref}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-xs md:text-sm btn user-session-dropdown w-header-menu"
        type="button"
      >
        <UserIcon
          width="1em"
          className="inline-block fill-current text-grey-400 user-icon"
        />
        <span className="mx-1 font-medium">
          {I18n.t("header.session.account")}
        </span>
        <ChevronDown
          className={classNames(
            "inline-block fill-current text-primary",
            isOpen && "transform rotate-180"
          )}
        />
      </button>
      <div
        className={classNames(
          "absolute inset-x-0 border rounded-sm border-grey-200 mt-3 opacity-0 pointer-events-none transition-opacity duration-150",
          isOpen && "opacity-100 pointer-events-auto"
        )}
      >
        <a
          href={userMenu.links.my_courses}
          className="flex py-2 px-1 w-full text-left bg-white hover:bg-brand-100"
        >
          <span className="mx-1 font-medium">
            {I18n.t("header.session.my_courses")}
          </span>
        </a>
        <a
          href={userMenu.links.my_certificates}
          className="flex py-2 px-1 w-full text-left bg-white hover:bg-brand-100"
        >
          <span className="mx-1 font-medium">
            {I18n.t("header.session.my_certificates")}
          </span>
        </a>
        <a
          href={userMenu.links.favorites}
          className="flex py-2 px-1 w-full text-left bg-white hover:bg-brand-100"
        >
          <span className="mx-1 font-medium">
            {I18n.t("header.session.favorites")}
          </span>
        </a>
        <a
          href={userMenu.links.change_password}
          className="flex py-2 px-1 w-full text-left bg-white hover:bg-brand-100"
        >
          <span className="mx-1 font-medium">
            {I18n.t("header.session.change_password")}
          </span>
        </a>
        <form action={userMenu.authenticationLinks.signOut} method="POST">
          <input name="_method" type="hidden" value="delete" />
          <input
            name="authenticity_token"
            type="hidden"
            value={getAuthenticityToken()}
          />
          <button
            className="flex py-2 px-1 w-full text-left bg-white hover:bg-brand-100"
            type="submit"
          >
            <span className="mx-1 font-medium">
              {I18n.t("header.session.sign_out")}
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};

const HeaderUserMenu = ({ userMenu }) => {
  if (userMenu.isAuthenticated) {
    return <HeaderUserMenuAuthenticated userMenu={userMenu} />;
  }
  return <HeaderUserMenuUnAuthenticated userMenu={userMenu} />;
};

const sessionProps = {
  userMenu: PropTypes.shape({
    user: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    links: PropTypes.shape({
      signIn: PropTypes.string,
      my_courses: PropTypes.string,
      my_certificates: PropTypes.string,
      favorites: PropTypes.string,
      change_password: PropTypes.string,
      signOut: PropTypes.string,
    }),
    authenticationLinks: PropTypes.shape({
      signIn: PropTypes.string,
      signOut: PropTypes.string,
    }),
    isAuthenticated: PropTypes.bool,
  }),
};

HeaderUserMenu.propTypes = sessionProps;
HeaderUserMenuAuthenticated.propTypes = sessionProps;
HeaderUserMenuUnAuthenticated.propTypes = sessionProps;

export default HeaderUserMenu;
