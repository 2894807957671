import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { toast } from "react-toastify";
import ExamStatus from "./ExamStatus";
import Progress from "../common/Progress";
import LeaveTestModal from "./LeaveTestModal";
import "../common/RadioButton.scss";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";

const ExamContent = ({ initialContent = [], onNext }) => {
  const [content] = useState(initialContent.exams);
  const [progressContent, setProgressContent] = useState(initialContent);
  const [examId, setExamId] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [examParams, setExamParams] = useState({
    status: initialContent.status,
    downloadUrl: initialContent.downloadUrl,
    answer: null,
    currentQuestion:
      initialContent.currentQuestion == null
        ? 1
        : initialContent.currentQuestion,
  });

  const { totalQuestion } = initialContent;

  const setParams = (id, choice) => {
    setDisabled(false);
    setExamId(id);
    setExamParams({ ...examParams, answer: choice });
  };

  const submitExam = async () => {
    setDisabled(true);
    try {
      const { progress: newProgress } = await onNext({
        progressId: initialContent.id,
        examId,
        answer: examParams.answer,
        currentQuestion: examParams.currentQuestion + 1,
      });
      setProgressContent(newProgress);
      setExamParams({
        status: newProgress.status,
        downloadUrl: newProgress.downloadUrl,
        answer: null,
        currentQuestion: examParams.currentQuestion + 1,
      });
    } catch (e) {
      console.error(e);
      toast.error(t("errors.messages.server_error"));
    }
  };

  const goBack = () => {
    setExamParams({
      ...examParams,
      currentQuestion: examParams.currentQuestion - 1,
    });
  };

  const leavePopup = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const renderExamCard = () => {
    return (
      <div>
        <div className="mb-4 flex items-center">
          {examParams.currentQuestion === 1 ? null : (
            <div
              className="inline-block mr-6 cursor-pointer"
              onClick={goBack}
              onKeyDown={goBack}
              role="button"
              tabIndex="0"
            >
              <ChevronDown className="inline-block transform rotate-90 fill-current text-primary" />
            </div>
          )}
          <h5 className="inline-block font-semibold">
            <span className="text-grey-400 mr-3">
              {t("exam.index.question")}
            </span>
            <span className="text-grey-400">{examParams.currentQuestion}</span>
            <span className="text-grey-300">/{totalQuestion}</span>
          </h5>
        </div>
        <Progress
          currentProgress={examParams.currentQuestion}
          totalProgress={totalQuestion}
        />
        <div className="mt-4 mb-9 text-tiny text-grey-300">
          {t("exam.index.title")}
        </div>
        <div className="bg-white p-7 border border-grey-200">
          {content.map((contentProps, index) => (
            <div key={contentProps.id} className="mr-auto">
              <div
                className={classNames(
                  "mr-auto",
                  examParams.currentQuestion === index + 1 ? "block" : "hidden"
                )}
              >
                <h5 className="mb-8 font-semibold">{contentProps.question}</h5>
                <div>
                  {JSON.parse(contentProps.choices).map((choice, cIndex) => (
                    <div key={choice} className="flex items-center mb-4">
                      <input
                        id={`answer_${cIndex}_${contentProps.id}`}
                        type="radio"
                        value={choice}
                        name="answer"
                        onClick={() => setParams(contentProps.id, choice)}
                        className="hidden"
                      />
                      <label
                        htmlFor={`answer_${cIndex}_${contentProps.id}`}
                        className="flex items-start md:pl-4 py-1 hover:bg-brand-025 w-full cursor-pointer"
                      >
                        <span className="min-w-radio-button w-radio-button h-radio-button inline-block mr-4 rounded-full border border-grey-200" />
                        {choice}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between items-center mt-9">
          <div>
            <button
              type="button"
              onClick={leavePopup}
              className="text-sm underline cursor-pointer"
            >
              {t("exam.index.leave_test")}
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={submitExam}
              disabled={disabled}
              className={classNames(
                "text-sm py-2 px-4 cursor-pointer",
                examParams.answer === null
                  ? "bg-grey-100 text-grey-300 cursor-not-allowed"
                  : "bg-brand-300 text-white"
              )}
            >
              {examParams.currentQuestion === totalQuestion
                ? t("exam.index.submit")
                : t("exam.index.next")}
            </button>
          </div>
        </div>
        <LeaveTestModal
          isOpen={isOpen}
          onClose={onClose}
          leaveURL={initialContent.leaveURL}
        />
      </div>
    );
  };

  const renderExamStatus = () => {
    return (
      <ExamStatus
        initialContent={progressContent}
        status={examParams.status}
        downloadUrl={examParams.downloadUrl}
      />
    );
  };

  return (
    <div
      className={classNames(
        "py-13",
        examParams.status === null ? "bg-grey-050" : "bg-white"
      )}
    >
      <div
        className={classNames(
          "w-full md:w-exam-content mx-auto",
          examParams.status === null ? "container" : null
        )}
      >
        {examParams.status === null ? renderExamCard() : renderExamStatus()}
      </div>
    </div>
  );
};

ExamContent.propTypes = {
  initialContent: PropTypes.arrayOf(PropTypes.object),
  onNext: PropTypes.func,
};

export default ExamContent;
