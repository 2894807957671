import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import LessonIcon from "../../images/icons/lessons.svg?inline";
import PlayIcon from "../../images/icons/play.svg?inline";
import VerifiedIcon from "../../images/icons/verified.svg?inline";
import CheckCircleIcon from "../../images/icons/check-circle.svg?inline";

const MyCourseCard = ({
  className,
  name,
  category,
  thumbnailURL,
  url,
  speakerImageURL,
  speakerName,
  lessonCount,
  status,
  // trailerURL,
}) => {
  const renderStatus = () => {
    // i18n-tasks use:
    // t('my_courses.status.added')
    // t('my_courses.status.in_progress')
    // t('my_courses.status.take_test')
    // t('my_courses.status.completed')
    switch (status) {
      case "added":
        return (
          <div className="w-my-course-status border border-grey-150 rounded-2xl flex items-center bg-white text text-tiny py-tiny px-1">
            <div className="border border-brand-300 rounded-full p-tiny">
              <PlayIcon width={4} height={4} />
            </div>
            <span className="ml-1">{t(`my_courses.status.${status}`)}</span>
          </div>
        );
      case "in_progress":
        return (
          <div className="w-my-course-status border border-brand-025 rounded-2xl flex items-center bg-brand-025 text text-tiny text-brand-300 py-tiny px-1">
            <div className="border border-brand-300 rounded-full p-tiny">
              <PlayIcon width={4} height={4} />
            </div>
            <span className="ml-1">{t(`my_courses.status.${status}`)}</span>
          </div>
        );
      case "take_test":
        return (
          <div className="w-my-course-status border border-grey-150 rounded-2xl flex items-center bg-white text text-tiny py-tiny px-1">
            <CheckCircleIcon width={14} height={14} />
            <span className="ml-1 text text-brand-300">
              {t(`my_courses.status.${status}`)}
            </span>
          </div>
        );
      case "completed":
        return (
          <div className="w-my-course-status border border-success-100 rounded-2xl flex items-center bg-success-100 text text-tiny py-tiny px-1">
            <VerifiedIcon width={14} height={14} />
            <span className="ml-1 text text-success-200">
              {t(`my_courses.status.${status}`)}
            </span>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <a
      className={classNames(
        "block group bg-grey-050 transition-all duration-75 hover:shadow-blur-40 hover:bg-white max-w-sm",
        className
      )}
      href={url}
    >
      <div
        className="pt-16/9-thumb bg-center bg-contain bg-no-repeat relative"
        style={{ backgroundImage: `url(${thumbnailURL})` }}
      >
        <div className="absolute bottom-0 inset-x-0 text-white px-4 py-1 bg-grey-400 bg-opacity-70">
          <div className="flex justify-between items-center">
            <div className="inline-flex">
              <img
                src={speakerImageURL}
                className="rounded-full h-6 w-8"
                alt={speakerName}
              />
              <span className="text-xs font-semibold m-auto pl-1">
                {speakerName}
              </span>
            </div>
            <h6>
              <LessonIcon className="fill-current inline mr-1" />
              {t("courses.show.lesson_count", { n: lessonCount })}
            </h6>
          </div>
        </div>
      </div>
      <div className="p-4 h-my-course-card-content flex flex-col justify-between group-hover:border-b-2 border-brand-300">
        <div>
          <div className="flex justify-between items-center">
            <div className="truncate-1-lines uppercase border-l-2 transition-all duration-75 group-hover:border-0 border-brand-300 group-hover:pl-0 pl-1 text-grey-300 text-xs font-medium">
              {category}
            </div>
            <div>{renderStatus()}</div>
          </div>

          <div className="mt-3 font-semibold text-body truncate-2-lines">
            {name}
          </div>
        </div>
      </div>
    </a>
  );
};

MyCourseCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  speakerImageURL: PropTypes.string,
  speakerName: PropTypes.string,
  lessonCount: PropTypes.number,
  status: PropTypes.string,
  // trailerURL: PropTypes.string,
};

export default MyCourseCard;
