import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import LessonIcon from "../../images/icons/lessons.svg?inline";
import DownloadIcon from "../../images/icons/download.svg?inline";

const MyCourseCard = ({
  // eslint-disable-next-line no-unused-vars
  className,
  name,
  thumbnailURL,
  url,
  speakerImageURL,
  speakerName,
  lessonCount,
  downloadURL,
  fileName,
}) => {
  return (
    <>
      <div
        className={classNames(
          "block group bg-grey-050 transition-all duration-75 hover:shadow-blur-40 hover:bg-white w-full",
          "hover:border-b-2 border-brand-300 h-full"
        )}
      >
        <div className="grid grid-cols-12 gap-x-6 p-4 md:p-7 h-full">
          <div className="col-span-12 md:col-span-5 relative">
            <div
              className="h-mobile-certificate-card-thumb md:h-full bg-center bg-cover bg-no-repeat relative"
              style={{ backgroundImage: `url(${thumbnailURL})` }}
            />

            <div className="absolute bottom-0 inset-x-0 text-white px-4 py-1 bg-grey-400 bg-opacity-70">
              <div className="md:hidden flex flex-0 justify-between items-center">
                <div className="inline-flex">
                  <img
                    src={speakerImageURL}
                    className="rounded-full h-6 w-8"
                    alt={speakerName}
                  />
                  <span className="text-xs font-semibold m-auto pl-1">
                    {speakerName}
                  </span>
                </div>
                <h6>
                  <LessonIcon className="fill-current inline mr-1 text-white md:text-brand-300" />
                  {t("courses.show.lesson_count", { n: lessonCount })}
                </h6>
              </div>
            </div>
          </div>

          <div className="col-span-12 md:col-span-7 flex flex-col">
            <div className="md:flex hidden flex-0 justify-between items-center">
              <div className="inline-flex">
                <img
                  src={speakerImageURL}
                  className="rounded-full h-6 w-8"
                  alt={speakerName}
                />
                <span className="text-xs font-semibold m-auto pl-1">
                  {speakerName}
                </span>
              </div>
              <h6>
                <LessonIcon className="fill-current inline mr-1 text-white md:text-brand-300" />
                {t("courses.show.lesson_count", { n: lessonCount })}
              </h6>
            </div>
            <h6 className="mt-4 mb-4 md:mb-0 md:mt-7 flex-1 font-semibold text-body">
              <a className="text-xs md:text-body truncate-2-lines" href={url}>
                {name}
              </a>
            </h6>
            <div className="flex flex-2">
              <a
                href={downloadURL}
                download={fileName}
                className="py-1 md:py-2 px-4 border border-grey-200 bg-white text-xs md:text-body"
              >
                <DownloadIcon className="inline-block" />
                <span className="ml-3 font-semibold">
                  {t("exam.index.download")}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MyCourseCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  speakerImageURL: PropTypes.string,
  speakerName: PropTypes.string,
  lessonCount: PropTypes.number,
  downloadURL: PropTypes.string,
  fileName: PropTypes.string,
};

export default MyCourseCard;
