import dateFormat from "dateformat";
import I18n from "i18n-js";
import prettyMilliseconds from "pretty-ms";

const { format: formatDateTH } = new Intl.DateTimeFormat("th-TH", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

export function formatDate(date) {
  const dateObject = date instanceof Date ? date : new Date(date);
  if (I18n.currentLocale() === "en") {
    return dateFormat(dateObject, "mmm dd, yyyy");
  }
  return formatDateTH(dateObject);
}

export function dateToDate(startDate, endDate, monthFormat = "short") {
  const startDateObject =
    startDate instanceof Date ? startDate : new Date(startDate);
  const endDateObject = endDate instanceof Date ? endDate : new Date(endDate);

  if (startDateObject.getMonth() === endDateObject.getMonth()) {
    const startDay = startDateObject.getDate();
    const endDay = endDateObject.getDate();
    const month = startDateObject.toLocaleString("default", {
      month: monthFormat,
    });
    const year = startDateObject.getFullYear();

    if (I18n.currentLocale() === "en") {
      return `${month} ${startDay} - ${endDay}, ${year}`;
    }
    const monthAndYear = new Intl.DateTimeFormat("th-TH", {
      year: "numeric",
      month: "short",
    }).format(startDateObject);
    return startDay === endDay
      ? `${startDay} ${monthAndYear}`
      : `${startDay} - ${endDay} ${monthAndYear}`;
  }
  if (I18n.currentLocale() === "en") {
    return `${dateFormat(startDateObject, "mmm dd, yyyy")} - ${dateFormat(
      endDateObject,
      "mmm dd, yyyy"
    )}`;
  }
  return `${formatDateTH(startDateObject)} - ${formatDateTH(endDateObject)}`;
}

export function timeRange(startDate, endDate) {
  const startDateObject =
    startDate instanceof Date ? startDate : new Date(startDate);
  const endDateObject = endDate instanceof Date ? endDate : new Date(endDate);
  const startTime = dateFormat(startDateObject, "h:MM TT");
  const endTime = dateFormat(endDateObject, "h:MM TT");

  return `${startTime} - ${endTime}`;
}

export function minuteSecond(durationSeconds) {
  if (Number.isNaN(+durationSeconds)) {
    return "0:00";
  }

  return prettyMilliseconds(durationSeconds * 1000, { colonNotation: true });
}

export function onlyYear(date) {
  const dateObject = new Date(date);
  if (I18n.currentLocale() === "en") {
    return dateFormat(dateObject, "yyyy");
  }

  const yearTH = new Intl.DateTimeFormat("th-TH", { year: "numeric" }).format(
    dateObject
  );
  return yearTH.match(/\d+/)[0];
}
