import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { sortings } from "utils/sortings";
import RecipeFilterMenuBase from "./RecipeFilterMenuBase";
import CheckIcon from "../../images/icons/check.svg?inline";

const RecipeSortMenu = ({
  isOpen,
  sortBy,
  onOpen,
  onClose,
  onSortingClick,
}) => {
  // i18n-tasks use:
  // t('recipes.sort_by.options.latest')
  // t('recipes.sort_by.options.rating')
  // t('recipes.sort_by.options.name:asc')
  // t('recipes.sort_by.options.name:desc')
  return (
    <RecipeFilterMenuBase
      title={
        <>
          <span className="text-grey-300 font-light mr-1">
            {t("recipes.sort_by.title")}
          </span>
          <span className="font-medium">
            {t(`recipes.sort_by.options.${sortBy}`)}
          </span>
        </>
      }
      menuTitle={t("recipes.sort_by.title")}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      {Object.keys(sortings).map((sorting) => {
        const isActive = sortBy === sorting;
        return (
          <button
            className={classNames(
              "btn w-full flex justify-between items-center p-4 mb-4 last:mb-0 font-medium rounded-none md:m-0 md:border-0 md:hover:bg-brand-025",
              isActive && "md:bg-brand-025"
            )}
            key={sorting}
            type="button"
            onClick={() => {
              onSortingClick(sorting);
              onClose();
            }}
          >
            <span
              className={classNames(
                "md:font-light md:text-xs",
                isActive && "md:font-medium"
              )}
            >
              {t(`recipes.sort_by.options.${sorting}`)}
            </span>
            {isActive && <CheckIcon className="md:hidden" />}
          </button>
        );
      })}
    </RecipeFilterMenuBase>
  );
};

RecipeSortMenu.propTypes = {
  isOpen: PropTypes.bool,
  sortBy: PropTypes.string,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSortingClick: PropTypes.func.isRequired,
};

export default RecipeSortMenu;
