import React, { useState } from "react";
import PropTypes from "prop-types";
import filesize from "filesize";
import mime from "mime-types";
import classNames from "classnames";
import Collapsible from "react-collapsible";
import { t } from "i18n-js";
import downloadable from "api/downloadable";
import ChevronDownIcon from "../../images/icons/chevron-down.svg?inline";
import DownloadIcon from "../../images/icons/download.svg?inline";

const fetchMaterial = async (itemURL, file) => {
  const response = await downloadable.get(itemURL);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", file.metadata.filename);
  document.body.appendChild(link);
  link.click();
};

const CourseMaterials = ({ lessons }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="border border-grey-150 px-4">
      <Collapsible
        open={isOpen}
        onOpening={() => {
          setIsOpen(true);
        }}
        onClosing={() => {
          setIsOpen(false);
        }}
        trigger={
          <div className="py-6 flex items-center justify-between border-b border-grey-150">
            <span className="text-body font-medium">
              {t("courses.show.material.title")}
            </span>
            <ChevronDownIcon
              className={classNames(
                "fill-current text-brand-300 w-4 h-3 cursor-pointer",
                isOpen && "transform rotate-180"
              )}
            />
          </div>
        }
      >
        <div className="divide-y divide-grey-150 max-h-course-material overflow-auto">
          {lessons.map((lesson, i) => (
            <div
              className="flex py-6 items-start justify-between"
              key={lesson.id}
            >
              <div className="w-4/5">
                {t("courses.show.material.episode", {
                  i: i + 1,
                  name: lesson.name,
                })}
                {lesson.file && (
                  <div className="text-xs text-grey-300">
                    <span className="uppercase">
                      {mime.extension(lesson.file.metadata.mime_type)}
                    </span>
                    {" • "}
                    {filesize(lesson.file.metadata.size)}
                  </div>
                )}
              </div>
              {lesson.file && (
                <div>
                  <button
                    className="btn font-medium cursor-pointer"
                    onClick={() => fetchMaterial(lesson.fileURL, lesson.file)}
                    type="button"
                  >
                    <DownloadIcon />
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </Collapsible>
      <button
        className="py-6 border-t border-grey-150 text-brand-300 w-full text-xs font-medium focus:outline-none"
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? t("courses.show.show_less") : t("courses.show.show_more")}
      </button>
    </div>
  );
};

CourseMaterials.propTypes = {
  lessons: PropTypes.array,
};

export default CourseMaterials;
