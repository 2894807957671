/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "lodash";
import { t } from "i18n-js";
import Player from "@vimeo/player";
import { minuteSecond } from "utils/format";
import { LessonProgressTracker } from "utils/lessonProgressTracker";
import { getVimeo } from "utils/vimeo";
import ExamImage from "images/exam.png";
import VerifiedIcon from "images/icons/verified.png";
import CourseTriangles from "../../images/courses/course-triangles.svg?inline";
import PlayIcon from "../../images/icons/play.svg?inline";
import PauseIcon from "../../images/icons/pause.svg?inline";
import CheckIcon from "../../images/icons/check.svg?inline";
import "./CourseLessons.scss";
import ChevronDownIcon from "../../images/icons/chevron-down.svg?inline";

function getFinishedLessons(lessons) {
  const finishedLessons = {};
  lessons.forEach(({ id, progressPercentage }) => {
    if (progressPercentage >= 100) {
      finishedLessons[id] = true;
    }
  });
  return finishedLessons;
}

function getLessonIndex(lessons, unfinishedLessons, finishedLessons) {
  let lessonIndex;

  if (_.isEmpty(finishedLessons)) {
    return 0;
  }

  if (unfinishedLessons.length > 0) {
    // eslint-disable-next-line array-callback-return
    lessons.map((lesson, index) => {
      if (
        finishedLessons[lesson.id] === undefined &&
        finishedLessons[lesson.id] !== true
      ) {
        lessonIndex = index;
      }
    });
  } else {
    lessonIndex = 0;
  }
  return lessonIndex === undefined ? 0 : lessonIndex;
}

// TODO: Badge for initially finished course
// TODO: Auto pop-up for finished courses
const CourseLessons = ({
  trailerURL,
  lessons,
  disabled,
  onOverlayClick,
  onComplete,
  progress,
  allLessonFinished,
  examCount,
}) => {
  const [finishedLessons, setFinishedLessons] = useState(
    getFinishedLessons(lessons)
  );

  const [unfinishedLessons] = useState(
    lessons.filter((lesson) => finishedLessons[lesson.id] !== true)
  );

  const [lessonIndex, setLessonIndex] = useState(
    getLessonIndex(lessons, unfinishedLessons, finishedLessons)
  );

  const activeLesson = lessons[lessonIndex];
  const [lessonToPlay, setLessonToPlay] = useState(
    unfinishedLessons.length > 0 ? unfinishedLessons[0] : activeLesson
  );

  const startLesson = (index) => {
    setLessonIndex(index);
    setLessonToPlay(lessons[index]);
  };

  const [isActive, setIsActive] = useState(false);
  const vimeoTrackerRef = useRef();

  const [vimeoDurations, setVimeoDurations] = useState({});
  useEffect(() => {
    Promise.all(
      lessons.map(({ id, videoURL }) =>
        getVimeo(videoURL).then((data) => ({
          id,
          data,
        }))
      )
    ).then((results) => {
      const newVimeoDurations = {};
      results.forEach(({ data, id }) => {
        newVimeoDurations[id] = data.duration;
      });
      setVimeoDurations(newVimeoDurations);
      if (unfinishedLessons.length > 0 && !disabled) {
        setIsActive(true);
      }
    });
  }, [lessons, unfinishedLessons.length, disabled]);

  const trailerPlayerRef = useRef();
  useEffect(() => {
    if (!isActive) {
      const player = new Player("vimeo-container", {
        url: trailerURL,
      });
      trailerPlayerRef.current = player;
    } else {
      trailerPlayerRef.current.destroy();
    }
  }, [isActive, trailerURL]);

  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    if (isActive && Object.keys(finishedLessons).length === lessons.length) {
      onComplete();
    }
  }, [isActive, onComplete, finishedLessons, lessons]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    setIsPlaying(false);
    if (vimeoTrackerRef.current) {
      vimeoTrackerRef.current.destroy();
    }

    const player = new Player("vimeo-container", {
      url: lessonToPlay.videoURL,
      controls: true,
    });
    player.setVolume(1);
    player.on("play", () => {
      setIsActive(true);
      setIsPlaying(true);
    });
    player.on("pause", () => {
      setIsPlaying(false);
    });

    player.ready().then(() => {
      player.play();
    });

    const tracker = new LessonProgressTracker(
      player,
      lessonToPlay.id,
      (lessonId) => {
        setFinishedLessons((f) => ({ ...f, [lessonId]: true }));
      }
    );
    tracker.init();
    vimeoTrackerRef.current = tracker;
  }, [isActive, lessonToPlay]);

  useEffect(() => {
    return () => {
      if (vimeoTrackerRef.current) {
        vimeoTrackerRef.current.destroy();
      }
    };
  }, []);

  const toggleVideoPlay = () => {
    if (!vimeoTrackerRef.current) {
      return;
    }

    if (isPlaying) {
      vimeoTrackerRef.current.player.pause();
    } else {
      vimeoTrackerRef.current.player.play();
    }
  };

  return (
    <div className="relative">
      <CourseTriangles className="hidden md:block absolute right-0 bottom-0" />
      <div className="course-lessons md:container md:mx-auto md:pb-13 relative">
        <div className="flex flex-col md:flex-row md:items-stretch md:h-course-top-section xl:h-course-top-section-xl md:shadow-blur-40">
          <div className="md:w-8/12">
            <div
              className="course-lessons__vimeo-container"
              id="vimeo-container"
            />
          </div>
          <div className="container mx-auto mt-10 md:w-4/12 md:bg-white md:m-0 md:p-0 md:flex md:flex-col">
            <div className="text-sm font-medium mb-8 flex-none md:px-6 md:mb-0 md:py-4">
              {t("courses.show.lessons")}
            </div>
            {allLessonFinished &&
              examCount > 0 &&
              (progress.status === null || progress.status === "failed") && (
                <a
                  href={progress.examLink}
                  className="md:px-6 focus:outline-none cursor-pointer hover:shadow-blur-40 hover:bg-white"
                >
                  <div className="flex items-center py-3 text-left border-l-2 border-brand-300 ">
                    <div className="ml-7">
                      <img
                        src={ExamImage}
                        alt="exam"
                        className="inline-block"
                        width="58"
                        height="58"
                      />
                    </div>
                    <div className="ml-7 flex-1">
                      <div className="text text-brand-300">
                        {t("courses.show.continue")}
                      </div>
                      <div className="text text-brand-300 font-semibold">
                        {t("courses.show.take_exam")}
                      </div>
                    </div>
                    <ChevronDownIcon className="mr-1 inline-block transform -rotate-90 fill-current text-primary" />
                  </div>
                </a>
              )}
            {((allLessonFinished && examCount === 0) ||
              progress.status === "passed") && (
              <div className="md:px-6 mb-4">
                <div className="flex items-center py-1 text-left bg-success-100 rounded-3xl">
                  <div className="pl-4 pr-2">
                    <img
                      src={VerifiedIcon}
                      alt="exam"
                      className="inline-block"
                      width="18"
                      height="18"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="text text-success-200 text-xs">
                      {t("courses.show.well_done")}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="max-h-mobile-course-lessons md:max-h-full flex-1 overflow-auto md:px-6 m-0">
              {lessons.map(({ id, name }, i) => {
                const isLessonActive = isActive && lessonIndex === i;
                return (
                  <div>
                    <div
                      className={classNames(
                        "flex items-center py-3 border-b border-grey-150 text-left focus:outline-none cursor-pointer",
                        isLessonActive
                          ? "bg-brand-300 text-white"
                          : "hover:bg-brand-050"
                      )}
                      key={id}
                      onClick={() => {
                        startLesson(i);
                        setIsActive(true);
                      }}
                    >
                      {isLessonActive ? (
                        <button
                          className="mx-4 w-6 h-6 bg-white border border-grey-300 rounded-full flex-none flex items-center justify-center focus:outline-none"
                          type="button"
                          onClick={toggleVideoPlay}
                        >
                          {isPlaying ? <PauseIcon /> : <PlayIcon />}
                        </button>
                      ) : (
                        <div className="px-4 text-xs">{i + 1}.</div>
                      )}
                      <div className="flex-1">
                        <ol>
                          <li className="text-xs font-medium mb-1 pr-10">
                            {name}
                          </li>
                        </ol>
                        <div
                          className={classNames(
                            "text-tiny",
                            isLessonActive ? "text-white" : "text-grey-300"
                          )}
                        >
                          {vimeoDurations[id]
                            ? minuteSecond(vimeoDurations[id])
                            : "--.--"}
                        </div>
                      </div>
                      {finishedLessons[id] && (
                        <CheckIcon
                          className={classNames(
                            "flex-none fill-current mr-1",
                            isLessonActive ? "text-white" : "text-success-200"
                          )}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {disabled && (
            <div
              className="absolute inset-0 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onOverlayClick();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CourseLessons.propTypes = {
  trailerURL: PropTypes.string,
  lessons: PropTypes.array,
  disabled: PropTypes.bool,
  onOverlayClick: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  progress: PropTypes.arrayOf(PropTypes.object),
  allLessonFinished: PropTypes.bool,
  examCount: PropTypes.number,
};

export default CourseLessons;
