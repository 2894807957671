/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { toast } from "react-toastify";
import StarIcon from "../../images/icons/star.svg?inline";
import VerifiedIcon from "../../images/icons/verified.svg?inline";
import "./Rate.scss";

const MAX_RATING = 5;

const Rate = ({
  id,
  onRate,
  redirect,
  defaultHasRated = false,
  feedback = false,
  setShowFeedback,
  postRating,
  newLineStars,
  rateTitle,
}) => {
  const [hasRated, setHasRated] = useState(defaultHasRated);
  const [rating, setRating] = useState(0);
  const handleTouch = (e) => {
    e.stopPropagation();
    const touch = e.targetTouches[0];
    const touchedElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    );
    const parent = touchedElement.closest("svg");
    if (!parent) {
      setRating(0);
      return;
    }

    const rate = parent.getAttribute("data-rate");
    if (typeof rate !== "undefined") {
      setRating(+rate);
    }
  };

  const handleRate = async () => {
    if (redirect) {
      window.location.href = redirect;
      return;
    }

    try {
      if (onRate) {
        onRate(rating);
      } else {
        await postRating(id, rating);
        if (feedback) {
          setShowFeedback(true);
        }
        setHasRated(true);
      }
    } catch (e) {
      console.error(e);
      toast.error(t("errors.messages.server_error"));
    }
  };

  return (
    <div
      className={classNames(
        "rate flex md:inline-flex flex-wrap items-center justify-center",
        newLineStars ? "flex-col" : null
      )}
      onMouseLeave={() => setRating(0)}
      onTouchStart={handleTouch}
      onTouchMove={handleTouch}
    >
      {hasRated ? (
        <div className="flex justify-center items-center">
          <VerifiedIcon className="rate__verified mr-1" />
          <span className="text-success-200 font-medium">
            {t("recipes.show.rate.thank_you")}
          </span>
        </div>
      ) : (
        <>
          <div
            className={classNames(
              "flex items-center font-medium text-body text-center md:text-left md:w-auto",
              newLineStars ? "mr-0 mb-4" : "mb-4 md:mr-4 md:mb-0"
            )}
          >
            {rateTitle}
          </div>
          <div className="flex justify-center items-center ">
            {Array(MAX_RATING)
              .fill()
              .map((_, i) => (
                <StarIcon
                  data-rate={i + 1}
                  className={classNames(
                    "rate__star fill-current",
                    i + 1 <= rating ? "text-brand-300" : "text-grey-150"
                  )}
                  key={i}
                  onMouseEnter={() => setRating(i + 1)}
                  onClick={handleRate}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

Rate.propTypes = {
  id: PropTypes.number,
  onRate: PropTypes.func,
  defaultHasRated: PropTypes.bool,
  feedback: PropTypes.bool,
  setShowFeedback: PropTypes.func,
  redirect: PropTypes.string,
  postRating: PropTypes.func,
  newLineStars: PropTypes.bool,
  rateTitle: PropTypes.string,
};

export default Rate;
