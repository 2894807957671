import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import api from "api";
import { serializeFilters } from "utils/recipe";
import ContentListing from "components/common/ContentListing";
import { sortingToParam } from "utils/sortings";
import { t } from "i18n-js";
import RecipeCard from "./RecipeCard";
import RecipeFilters from "./RecipeFilters";

async function fetchRecipes(
  { page, perPage, filters, sort },
  { cancelToken } = {}
) {
  const recipeBase = filters
    ? `/recipes?${serializeFilters(filters)}`
    : "/recipes";
  const response = await api.get(recipeBase, {
    params: {
      page,
      per_page: perPage,
      sort: sort ? sortingToParam(sort) : undefined,
    },
    cancelToken,
  });
  return { content: response.data, pageCount: response.headers["page-count"] };
}

const RecipeListing = ({
  recipes = [],
  onLoadMore = fetchRecipes,
  filterButtonId,
  filtersId,
  filters = {},
}) => {
  const [sort, setSort] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const fetch = useCallback(
    ({ page, perPage }, { cancelToken } = {}) => {
      return onLoadMore(
        { page, perPage, filters: selectedFilters, sort },
        { cancelToken }
      );
    },
    // Filter changes invalidates useCallback's cache
    // which then trigger's usePagination's `onLoadMore`
    [onLoadMore, selectedFilters, sort]
  );

  return (
    <>
      {filterButtonId && filtersId && isMounted && (
        <RecipeFilters
          filters={filters}
          buttonId={filterButtonId}
          filtersId={filtersId}
          shouldUpdateURLParams
          onFilterChange={(newFilters) => {
            setSelectedFilters(newFilters);
          }}
          onSortingChange={(newSorting) => {
            setSort(newSorting);
          }}
        />
      )}
      <ContentListing
        emptyContent={
          <span className="text-grey-300">{t("recipes.empty")}</span>
        }
        itemComponent={RecipeCard}
        initialContent={recipes}
        onLoadMore={fetch}
        perPage={8}
        itemClassName="col-span-full sm:col-span-6 md:col-span-3"
      />
    </>
  );
};

RecipeListing.propTypes = {
  recipes: PropTypes.array,
  onLoadMore: PropTypes.func,
  filterButtonId: PropTypes.string,
  filtersId: PropTypes.string,
  filters: PropTypes.object,
};

export default RecipeListing;
