import React from "react";
import PropTypes from "prop-types";
import "react-multi-carousel/lib/styles.css";
import Carousel from "components/common/Carousel";

const EventCarousel = ({ children }) => {
  return (
    <Carousel
      cursorPosition="absolute w-screen inset-x-0 bottom-mobile-border-carousel-control transform -translate-y-1/2 md:transform-none flex justify-between md:static md:w-auto whitespace-no-wrap md:ml-2"
      cursorBorder="w-3/4 relative md:w-9/12 mb-7 mx-auto border-t-2 border-grey-200"
    >
      {children}
    </Carousel>
  );
};

EventCarousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EventCarousel;
