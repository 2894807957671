import React, { useState } from "react";
import PropTypes from "prop-types";
import FlipMove from "react-flip-move";
import { t } from "i18n-js";
import { dateToDate } from "utils/format";
import EventCarousel from "./EventCarousel";

const ThisWeek = ({
  itemComponent,
  mobileItemComponent,
  initialContent = [],
}) => {
  const [content] = useState(initialContent);
  const Item = itemComponent;
  const MobileItem = mobileItemComponent;

  return (
    <>
      {content.length !== 0 && (
        <>
          <div className="bg-grey-050 pb-8 pt-9 hidden sm:block">
            <div className="container mx-auto">
              <div className="inline-flex">
                <h2 className="font-medium self-center">
                  {t("events.this_week")}
                </h2>
                <div className="px-4 py-1 ml-4 bg-white self-center text-center rounded-3xl">
                  {dateToDate(
                    content[0].startWeekDate,
                    content[0].endWeekDate,
                    "long"
                  )}
                </div>
              </div>
            </div>
            <div className="container mx-auto mt-10">
              <FlipMove className="flex flex-wrap -mx-3" staggerDelayBy={100}>
                {content.map((contentProps) => (
                  <div
                    key={contentProps.slug}
                    className="mx-auto mb-7 sm:w-1/2 md:w-full px-3 md:mb-10"
                  >
                    <Item {...contentProps} className="mx-auto" />
                  </div>
                ))}
              </FlipMove>
            </div>
          </div>
          <div className="bg-grey-050 pb-9 pt-11 block sm:hidden">
            <div className="container mx-auto mb-1">
              <div className="flex justify-between items-center">
                <h2 className="font-medium">{t("events.this_week")}</h2>
                <div className="px-4 py-1 bg-white self-center text-center rounded-3xl">
                  {dateToDate(content[0].startWeekDate, content[0].endWeekDate)}
                </div>
              </div>
            </div>
            <EventCarousel>
              {content.map((contentProps) => (
                <div key={contentProps.slug}>
                  <MobileItem {...contentProps} className="mx-auto" />
                </div>
              ))}
            </EventCarousel>
          </div>
        </>
      )}
    </>
  );
};

ThisWeek.propTypes = {
  initialContent: PropTypes.arrayOf(PropTypes.object),
  itemComponent: PropTypes.elementType.isRequired,
  mobileItemComponent: PropTypes.elementType.isRequired,
};

export default ThisWeek;
