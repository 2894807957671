import React from "react";
import PropTypes from "prop-types";

import CardsCarousel from "components/common/CardsCarousel";
import ArticleCard from "./ArticleCard";

const ArticleCardsCarousel = ({ title, isMobile, articles = [] }) => {
  return (
    <CardsCarousel
      title={title}
      isMobile={isMobile}
      content={articles}
      cardComponent={ArticleCard}
    />
  );
};

ArticleCardsCarousel.propTypes = {
  title: PropTypes.string,
  isMobile: PropTypes.bool,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      thumbnailURL: PropTypes.string.isRequired,
      publishedAt: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
      url: PropTypes.string.isRequired,
    })
  ),
};

export default ArticleCardsCarousel;
