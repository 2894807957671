import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { chunk } from "lodash";
import Carousel from "./Carousel";
import CarouselControl from "./CarouselControl";

const CardsCarousel = ({
  title,
  isMobile,
  content,
  cardComponent,
  moreCardsPerSlide,
}) => {
  let chunkSize;
  if (isMobile) {
    chunkSize = 1;
  } else if (moreCardsPerSlide) {
    chunkSize = 4;
  } else {
    chunkSize = 3;
  }
  const chunks = chunk(content, chunkSize);
  const Card = cardComponent;

  return (
    <Carousel
      title={
        <h3 className="container mx-auto font-medium mb-7 md:mb-11 leading-loose">
          {title}
        </h3>
      }
      customButtonGroup={
        <CarouselControl
          wrapperClassName={
            isMobile ? "w-full pt-8" : "absolute top-0 inset-x-0"
          }
          elementClassName="md:w-4/12"
          buttonClassName={isMobile ? "hidden" : ""}
        />
      }
      partialVisible={isMobile}
    >
      {chunks.map((c) => (
        <div
          className={classNames(
            "container mx-auto md:pb-13",
            !isMobile && "grid grid-cols-12 gap-7"
          )}
          key={c[0].slug}
        >
          {c.map((cardProps) => (
            <Card
              key={cardProps.slug}
              className={classNames(
                "inline-block col-span-full",
                moreCardsPerSlide ? "md:col-span-3" : "md:col-span-4"
              )}
              {...cardProps}
            />
          ))}
        </div>
      ))}
    </Carousel>
  );
};

CardsCarousel.propTypes = {
  title: PropTypes.string,
  isMobile: PropTypes.bool,
  content: PropTypes.array,
  cardComponent: PropTypes.elementType,
  moreCardsPerSlide: PropTypes.bool,
};

export default CardsCarousel;
