import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactModal from "react-modal";
import useOnClickOutside from "hooks/useOnClickOutside";
import breakpoints from "lib/breakpoints";
import CloseIcon from "../../images/icons/close.svg?inline";
import ChevronDownIcon from "../../images/icons/chevron-down.svg?inline";

const RecipeFilterMenuBase = ({
  isOpen = false,
  title,
  menuTitle,
  children,
  onOpen,
  onClose,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (window.innerWidth < Number(breakpoints.md.replace("px", ""))) {
      return;
    }

    if (isOpen) onClose();
  });

  const menuContent = (
    <div
      className={classNames(
        "absolute inset-0 z-10 bg-white md:bottom-auto",
        isOpen ? "md:opacity-1" : "md:opacity-0 md:pointer-events-none"
      )}
    >
      <div className="container mx-auto md:p-0 md:border border-grey-200 md:border-t-0 md:shadow-blur-40">
        <button className="py-6 md:hidden" onClick={onClose} type="button">
          <CloseIcon />
        </button>
        <div className="text-body font-medium mb-8 md:hidden">{menuTitle}</div>
        {children}
      </div>
    </div>
  );

  return (
    <>
      <div ref={ref}>
        <button
          className="flex w-full justify-between items-center px-6 py-3 md:border border-grey-200 md:p-4 md:text-xs focus:outline-none"
          type="button"
          onClick={() => {
            if (isOpen) {
              onClose();
            } else {
              onOpen();
            }
          }}
        >
          <span>{title}</span>
          <ChevronDownIcon
            className={classNames(
              "inline-block transform -rotate-90 text-brand-300 fill-current",
              isOpen ? "md:rotate-180" : "md:rotate-0"
            )}
          />
        </button>
        <div className="hidden md:block md:relative">{menuContent}</div>
      </div>
      <ReactModal
        isOpen={isOpen}
        closeTimeoutMS={200}
        overlayClassName="md:hidden"
        className={{
          base: "filter-menu-base__modal",
          afterOpen: "--after-open",
          beforeClose: "--before-close",
        }}
        ariaHideApp={false}
      >
        {menuContent}
      </ReactModal>
    </>
  );
};

RecipeFilterMenuBase.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.node,
  menuTitle: PropTypes.node,
  children: PropTypes.node,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RecipeFilterMenuBase;
