import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";

const RecipeFilterFieldHeader = ({
  className,
  field,
  onClick,
  selectedOptionCount = 0,
  isAllSelected = false,
}) => {
  // i18n-tasks use:
  // t('recipes.filter.options.cuisine')
  // t('recipes.filter.options.dish_type')
  // t('recipes.filter.options.main_ingredient')
  return (
    <button
      className={classNames(
        "btn flex justify-between items-center w-full text-left py-4 whitespace-no-wrap",
        className
      )}
      onClick={onClick}
      type="button"
    >
      <span className="font-medium md:text-xs">
        {t(`recipes.filter.options.${field}`)}
      </span>
      <span className="flex items-center">
        <span className="text-grey-300 mr-6 font-light text-xs">
          {isAllSelected
            ? t("recipes.filter.all")
            : t("recipes.filter.applied", { n: selectedOptionCount })}
        </span>
        <ChevronDown className="transform -rotate-90 text-brand-300 fill-current md:rotate-0" />
      </span>
    </button>
  );
};

RecipeFilterFieldHeader.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedOptionCount: PropTypes.number,
  isAllSelected: PropTypes.bool,
};

export default RecipeFilterFieldHeader;
