import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckIcon from "../../images/icons/check.svg?inline";
import "./RecipeFilterFieldOptions.scss";

const RecipeFilterFieldOptions = ({
  className,
  options,
  onSelect,
  isChecked,
}) => {
  return (
    <div className={classNames("recipe-filter-field-options", className)}>
      {options.map(({ value, label }) => (
        <label
          key={value}
          className="block px-7 mb-7 last:mb-0 cursor-pointer md:container"
          htmlFor={value}
        >
          <input
            type="checkbox"
            id={value}
            checked={isChecked(value)}
            onChange={() => {
              onSelect(value);
            }}
            hidden
          />
          <div className="inline-flex justify-center items-center w-6 h-6 border border-grey-200 rounded-md align-middle checked-container">
            <CheckIcon className="text-white fill-current" />
          </div>
          <span
            className={classNames(
              "ml-3 font-light leading-4 md:text-xs md:font-normal",
              isChecked(value) && "font-medium md:font-medium"
            )}
          >
            {label}
          </span>
        </label>
      ))}
    </div>
  );
};

RecipeFilterFieldOptions.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
};

export default RecipeFilterFieldOptions;
