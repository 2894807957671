import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { formatDate } from "utils/format";
import { t } from "i18n-js";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";

const ArticleCard = ({
  className,
  title,
  category,
  thumbnailURL,
  publishedAt,
  url,
}) => {
  return (
    <a
      className={classNames(
        "block group bg-grey-050 transition-all duration-75 hover:shadow-blur-40 hover:bg-white max-w-sm",
        className
      )}
      href={url}
    >
      <div
        className="pt-16/9-thumb bg-center bg-contain bg-no-repeat"
        style={{ backgroundImage: `url(${thumbnailURL})` }}
      />
      <div className="p-4 h-mobile-article-card-content flex flex-col justify-between sm:h-article-card-content group-hover:border-b-2 border-brand-300">
        <div>
          <div className="uppercase border-l-2 transition-all duration-75 group-hover:border-0 border-brand-300 group-hover:pl-0 pl-1 text-grey-300 text-xs font-medium">
            {category}
          </div>
          <div className="mt-3 font-semibold text-body truncate-3-lines">
            {title}
          </div>
        </div>
        <div className="flex justify-between content-center text-xs text-grey-300">
          <span className="uppercase">{formatDate(publishedAt)}</span>
          <div className="font-medium text-brand-300  transition-opacity duration-75 opacity-0 group-hover:opacity-100">
            {t("views.read_more")}
            <ChevronDown className="ml-2 transform -rotate-90 inline-block fill-current text-primary" />
          </div>
        </div>
      </div>
    </a>
  );
};

ArticleCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  publishedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  url: PropTypes.string.isRequired,
};

export default ArticleCard;
