export const sortings = {
  latest: { field: "created_at", order: "desc" },
  // rating: { field: "rating", order: "asc" },
  "name:asc": { field: "name", order: "asc" },
  "name:desc": { field: "name", order: "desc" },
};

export function sortingToParam(sorting) {
  const { field, order } = sortings[sorting];
  return `${field}:${order}`;
}
