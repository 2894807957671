/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import FlipMove from "react-flip-move";
import { t } from "i18n-js";
import { onlyYear } from "utils/format";

const PER_PAGE = 6;

const Upcoming = ({
  itemComponent,
  mobileItemComponent,
  onLoadMore,
  initialContent = [],
  lastPage,
}) => {
  const [isLastPage, setIsLastPage] = useState(lastPage);
  const [content, setContent] = useState(initialContent);
  const [isLoading, setIsLoading] = useState(false);
  const nextPageRef = useRef(2);
  const Item = itemComponent;
  const MobileItem = mobileItemComponent;

  const groupByYear = _.groupBy(content, (item) => {
    const date = new Date(item.startDate);
    return date.getFullYear();
  });

  const loadMore = async () => {
    setIsLoading(true);
    try {
      const { content: newContent, pageCount } = await onLoadMore({
        page: nextPageRef.current,
        perPage: PER_PAGE,
      });
      setIsLastPage(nextPageRef.current >= Number(pageCount));
      setContent([...content, ...newContent]);
      nextPageRef.current += 1;
    } finally {
      setIsLoading(false);
    }
  };

  const initializedRef = useRef(false);
  useEffect(() => {
    if (!initializedRef.current) {
      initializedRef.current = true;
      return;
    }

    setIsLoading(true);
    onLoadMore({ page: 1, perPage: PER_PAGE }).then(
      ({ content: newContent, pageCount }) => {
        setContent(newContent);
        setIsLoading(false);
        setIsLastPage(Number(pageCount) === 1);
        nextPageRef.current = 2;
      }
    );
  }, [onLoadMore]);

  return (
    <>
      <div className="pb-8 pt-12 sm:pt-9">
        <div className="container mx-auto sm:mt-0 mt-7">
          <h2 className="font-medium">{t("events.upcoming")}</h2>
        </div>
        <div className="container mx-auto mt-1 sm:mt-10">
          {Object.entries(groupByYear).map(([year, yearItems]) => (
            <div key={year} className="grid grid-cols-12">
              <div className="sm:col-span-2 col-span-12 mb-8 sm:mb-0">
                <h1 className="font-semibold text-grey-200">
                  {onlyYear(year)}
                </h1>
              </div>
              <div className="sm:col-span-10 col-span-12">
                <FlipMove className="flex flex-wrap -mx-3" staggerDelayBy={100}>
                  {yearItems.map((contentProps) => (
                    <div
                      key={contentProps.slug}
                      className="mx-auto mb-7 sm:w-1/2 md:w-full px-3 md:mb-9 sm:bg-grey-050"
                    >
                      <Item
                        {...contentProps}
                        className="mx-auto hidden sm:block"
                      />
                      <MobileItem
                        {...contentProps}
                        className="mx-auto sm:hidden block"
                      />
                    </div>
                  ))}
                </FlipMove>
              </div>
            </div>
          ))}
        </div>
      </div>
      {!isLastPage && (
        <button
          className={classNames(
            "btn block mx-auto text-brand-300 font-semibold",
            isLoading && "animate-pulse"
          )}
          onClick={loadMore}
          type="button"
          disabled={isLoading}
        >
          {t("views.pagination.load_more")}
        </button>
      )}
    </>
  );
};

Upcoming.propTypes = {
  initialContent: PropTypes.arrayOf(PropTypes.object),
  itemComponent: PropTypes.elementType.isRequired,
  mobileItemComponent: PropTypes.elementType.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  lastPage: PropTypes.bool,
};

export default Upcoming;
