import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "images/icons/close.svg?inline";
import ReactModal from "react-modal";

const HomeModal = ({ mobilePopupUrl, desktopPopupUrl, name }) => {
  const modalExpiredUntil = new Date(localStorage.getItem("modalExpiredUntil"));
  const now = new Date();
  const [isOpen, setIsOpen] = useState(now - modalExpiredUntil > 86400000);

  const onClose = () => {
    const modalExpiredUntilDate = new Date();
    localStorage.setItem("modalExpiredUntil", modalExpiredUntilDate);
    setIsOpen(false);
  };

  return (
    <>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => {
          onClose();
        }}
        overlayClassName="fixed z-50 inset-0 bg-grey-400 bg-opacity-70 flex items-center justify-center"
        className="mx-auto focus:outline-none z-50 overflow-y-auto"
      >
        <div className="h-full flex flex-col justify-between relative">
          <button
            type="button"
            className="ml-auto text-white bg-white bg-opacity-20 p-4 close"
            onClick={onClose}
          >
            <CloseIcon fill="#ffffff" className="w-4 h-4" />
          </button>
          <div className="mx-auto">
            <img
              alt={name}
              src={mobilePopupUrl}
              className="block md:hidden md:w-home-modal md:h-home-modal"
            />
            <img
              alt={name}
              src={desktopPopupUrl}
              className="hidden md:block md:w-home-modal md:h-home-modal"
            />
          </div>
        </div>
      </ReactModal>
    </>
  );
};

HomeModal.propTypes = {
  mobilePopupUrl: PropTypes.string,
  desktopPopupUrl: PropTypes.string,
  name: PropTypes.string,
};

export default HomeModal;
