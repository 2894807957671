import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Collapsible from "react-collapsible";
import { t } from "i18n-js";
import ChevronDown from "../images/icons/chevron-down.svg?inline";
import "./HeaderMenu.scss";

const HeaderMenu = ({
  isOpen,
  openedItemKey,
  expandableItems,
  menuItemCategories,
  linkItems,
  contentLinks,
  onItemClick,
  languageSwitcher,
}) => (
  <ul
    className={classNames(
      "block bg-white pt-mobile-header fixed z-10 inset-0 transform transition-transform duration-300 md:pt-0 md:static md:transform-none md:flex items-center overflow-auto md:overflow-hidden",
      isOpen ? "translate-x-0" : "-translate-x-full"
    )}
  >
    {expandableItems.map((key) => {
      const isItemOpen = key === openedItemKey;
      return (
        <li className="list-none">
          <Collapsible
            key={key}
            open={isItemOpen}
            trigger={
              <button
                type="button"
                className={classNames(
                  "header-menu-item container mx-auto transition-all duration-100 border-brand-300 md:mx-0 md:border-l-0 font-medium",
                  isItemOpen && "text-brand-300 border-l-4 md:border-l-0"
                )}
                onClick={() => {
                  onItemClick(key);
                }}
              >
                {t(`${key}.title`)}
                <ChevronDown
                  className={classNames(
                    "inline-block ml-2 fill-current text-primary",
                    isItemOpen && "transform rotate-180"
                  )}
                />
              </button>
            }
          >
            <div className="md:hidden container mx-auto bg-brand-025">
              <a className="header-menu-item pl-6" href={contentLinks[key]}>
                {t(`${key}.view_all`)}
              </a>
              {menuItemCategories[key]?.map(
                ({ slug, label, href: categoryHref }) => (
                  <a
                    key={slug}
                    className="header-menu-item pl-6"
                    href={categoryHref}
                  >
                    {label}
                  </a>
                )
              )}
            </div>
          </Collapsible>
        </li>
      );
    })}
    {linkItems.map(({ key, href }) => (
      <a
        key={key}
        className="header-menu-item container mx-auto font-medium"
        onClick={() => onItemClick(key)}
        href={href}
      >
        {t(`${key}.title`)}
      </a>
    ))}
    {languageSwitcher}
  </ul>
);

HeaderMenu.propTypes = {
  isOpen: PropTypes.bool,
  openedItemKey: PropTypes.string,
  expandableItems: PropTypes.array.isRequired,
  menuItemCategories: PropTypes.object,
  linkItems: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  languageSwitcher: PropTypes.node,
  contentLinks: PropTypes.object,
};

export default HeaderMenu;
