import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import SearchIcon from "../../images/icons/search.svg?inline";
import "./ContentListingCategories.scss";

const ContentListingCategories = ({
  categories,
  currentSlug,
  onCategoryChange,
  showAll = true,
}) => {
  const ref = useRef();
  const handleButtonClick = (e, slug) => {
    onCategoryChange(slug);
    if (!ref.current) {
      return;
    }

    const offset = e.target.offsetLeft - ref.current.offsetLeft;
    ref.current.scroll(offset - 16, 0);
    const documentOffsetTop =
      ref.current.getBoundingClientRect().top +
      document.documentElement.scrollTop;
    window.scroll(0, documentOffsetTop);
  };

  const [isStuck, setIsStuck] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) {
        return;
      }

      const { top } = ref.current.getBoundingClientRect();
      if (top <= 0) {
        setIsStuck(true);
      } else {
        setIsStuck(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isStuck]);

  const categoryArr = showAll
    ? [{ slug: "", label: t("categories.all") }].concat(categories)
    : categories;

  return (
    <div
      className={classNames(
        "content-listing-categories transition-shadow duration-75 sticky z-10 top-0 bg-white mt-10 md:mt-12",
        isStuck && "md:shadow-blur-40"
      )}
    >
      <div
        className={classNames(
          "md:container smooth-scroll mx-auto border-t md:border-t-0 border-b border-grey-150 mb-7 whitespace-no-wrap flex overflow-auto",
          isStuck && "md:border-b-0 md:flex"
        )}
        ref={ref}
      >
        <div className="inline-flex pr-4">
          {isStuck ? (
            <a
              href="#search_bar"
              type="button"
              className="btn w-10 h-10 p-4 border-t-0 border-r-1 md:hidden"
            >
              <SearchIcon className="mx-auto" />
            </a>
          ) : (
            <div />
          )}
        </div>
        <div className="transition-all duration-150 flex-grow">
          {categoryArr.map(({ slug, label }) => (
            <button
              key={slug}
              className={classNames(
                "text-sm md:text-body mr-8 last:mr-0 py-2 md:pt-5 md:pb-4 transition-all duration-150 border-b-2 border-white focus:outline-none",
                currentSlug === slug && "font-semibold border-brand-300"
              )}
              type="button"
              onClick={(e) => {
                handleButtonClick(e, slug);
              }}
            >
              {label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

ContentListingCategories.propTypes = {
  currentSlug: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onCategoryChange: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
};

export default ContentListingCategories;
