import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Eye from "../../images/icons/eye.svg?inline";
import EyeCrossed from "../../images/icons/eye-crossed.svg?inline";

const PasswordField = ({ id, name, placeholder, className }) => {
  const [inputType, setInputType] = useState("password");

  const toggleType = (e) => {
    e.preventDefault();

    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  return (
    <div
      className={classNames("form-control-password-field relative", className)}
    >
      <input
        placeholder={placeholder}
        className="text-grey border border-grey-200 p-1 pr-10 block w-full mb-8"
        type={inputType}
        name={name}
        id={id}
      />
      <Eye
        className={classNames(
          "eye mx-1 cursor-pointer right-0 top-0 absolute h-full",
          { hidden: inputType !== "password" }
        )}
        onClick={toggleType}
        role="button"
      />
      <EyeCrossed
        className={classNames(
          "eye mx-1 cursor-pointer right-0 top-0 absolute h-full",
          { hidden: inputType !== "text" }
        )}
        onClick={toggleType}
        role="button"
      />
    </div>
  );
};

PasswordField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default PasswordField;
