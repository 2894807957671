import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "lodash";
import { dateToDate, timeRange } from "utils/format";
import { t } from "i18n-js";
import Clock from "../../images/icons/clock.svg?inline";
import Pin from "../../images/icons/location-pin.svg?inline";

const ThisWeekCard = ({
  className,
  title,
  description,
  thumbnailURL,
  startDate,
  endDate,
  url,
  location,
}) => {
  return (
    <>
      <a
        className={classNames(
          "block group bg-grey-050 transition-all duration-75 hover:shadow-blur-40 hover:bg-white group-hover:border-b-2 border-brand-300",
          className
        )}
        href={url}
      >
        <div className="grid grid-cols-12 h-this-week-card-content py-7 px-7 gap-10">
          <div className="col-span-5">
            <div
              className="h-full bg-center bg-cover sm:h-article-card-thumb"
              style={{ backgroundImage: `url(${thumbnailURL})` }}
            />
          </div>
          <div className="col-span-7 flex flex-col">
            <div className="font-semibold text-brand-300 text-2xl border-l-2 border-brand-300 pl-4">
              {dateToDate(startDate, endDate)}
            </div>
            <div className="text-grey-400 text-xl mt-7 font-semibold">
              {title}
            </div>
            <div className="text-grey-300 text-sm mt-4">
              {_.truncate(description, { length: 250 })}
            </div>
            <div className="flex justify-between content-center mt-auto text-xs text-grey-300">
              <div>
                <Pin className="inline" />
                <div className="text-grey-300 text-xs font-semibold inline pl-1">
                  {t("events.location")}
                </div>
                <div className="text-grey-400 text-xs font-semibold">
                  {location}
                </div>
              </div>
              <div>
                <Clock className="inline" />
                <div className="text-grey-300 text-xs font-semibold inline pl-1">
                  {t("events.time")}
                </div>
                <div className="text-grey-400 text-xs font-semibold">
                  {timeRange(startDate, endDate)}
                </div>
              </div>
              <div>
                <button
                  className="w-100 d-block bg-white py-2 px-4 text-brand-300 border border-grey-200 font-semibold"
                  type="button"
                >
                  {t("events.see_details")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </a>
    </>
  );
};

ThisWeekCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  url: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

export default ThisWeekCard;
