import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { dateToDate, timeRange } from "utils/format";
import { t } from "i18n-js";
import Clock from "../../images/icons/clock.svg?inline";
import Pin from "../../images/icons/location-pin.svg?inline";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";

const MobileEventCard = ({
  slug,
  title,
  thumbnailURL,
  startDate,
  endDate,
  url,
  location,
}) => {
  return (
    <a
      key={slug}
      className="sm:hidden flex items-center flex-col-reverse h-full mx-auto bg-grey-050"
      href={url}
    >
      <div className="container mt-4 h-full flex flex-col justify-between">
        <div className="grid grid-cols-12 mt-4 text-xs">
          <div className="col-span-6">
            <div className="uppercase font-semibold text-brand-300 text-lg border-l-2 border-brand-300 pl-4">
              {dateToDate(startDate, endDate)}
            </div>
          </div>
          <div className="col-span-6">
            <img src={thumbnailURL} alt={title} className="w-full" />
          </div>
        </div>

        <div className="flex flex-col flex-1 justify-between mt-4 text-grey-400">
          <h5 className="font-medium pr-3">
            {_.truncate(title, { length: 60 })}
          </h5>
          <div>
            <div className="mt-4">
              <Pin className="inline" />
              <div className="inline text-grey-300 text-xs font-semibold inline pl-1">
                {t("events.location")}
              </div>
              <div className="inline text-grey-400 text-xs font-semibold pl-1">
                {location}
              </div>
            </div>
            <div className="mt-4">
              <Clock className="inline" />
              <div className="inline text-grey-300 text-xs font-semibold inline pl-1">
                {t("events.time")}
              </div>
              <div className="inline text-grey-400 text-xs font-semibold pl-1">
                {timeRange(startDate, endDate)}
              </div>
            </div>
            <div className="mt-7 mb-9">
              <button
                className="w-100 d-block bg-white py-2 px-4 text-tiny text-brand-300 border border-grey-200 font-semibold"
                type="button"
              >
                {t("events.see_details")}
                <ChevronDown className="ml-2 transform -rotate-90 inline-block fill-current" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

MobileEventCard.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  url: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

export default MobileEventCard;
