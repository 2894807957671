import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import FavoriteButton from "./FavoriteButton";

const RecipeFavoriteCard = ({
  className,
  title,
  thumbnailURL,
  cookingTime,
  url,
  recipeId,
  defaultIsFavorited,
  redirect,
  showText,
}) => {
  const onFavoriteButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <a
      className={classNames(
        "block group md:bg-grey-050 transition-all duration-75 hover:shadow-blur-40 hover:bg-white w-full max-w-sm md:mb-10",
        className
      )}
      href={url}
    >
      <div className="grid grid-cols-2 sm:block">
        <div
          className="w-favorite-recipe-image-mobile h-favorite-recipe-image-mobile sm:w-full sm:h-full sm:pt-16/9-thumb bg-center bg-contain bg-no-repeat"
          style={{ backgroundImage: `url(${thumbnailURL})` }}
        />
        <div className="sm:p-4 -ml-4 sm:ml-auto sm:ml-auto flex flex-col justify-between sm:h-recipe-card-content group-hover:border-b-2 border-brand-300">
          <div className="sm:mt-3 font-semibold text-xs sm:text-body flex justify-between">
            <div className="truncate-2-lines">{title}</div>
            <div className="block sm:hidden inline-flex">
              <FavoriteButton
                redirect={redirect}
                defaultIsFavorited={defaultIsFavorited}
                recipeId={recipeId}
                showText={showText}
                onClick={onFavoriteButtonClick}
              />
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div className="font-light text-xs text-grey-300">
              {t("recipes.cooking_time", { m: cookingTime })}
            </div>
            <div className="hidden sm:block">
              <FavoriteButton
                redirect={redirect}
                defaultIsFavorited={defaultIsFavorited}
                recipeId={recipeId}
                showText={showText}
                onClick={onFavoriteButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

RecipeFavoriteCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  cookingTime: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  defaultIsFavorited: PropTypes.bool,
  recipeId: PropTypes.number,
  showText: PropTypes.bool,
};

export default RecipeFavoriteCard;
