import React, { useState } from "react";
// import PropTypes from "prop-types";
import { getAuthenticityToken } from "utils/getAuthenticityToken";
import { t } from "i18n-js";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";

const LoginForm = ({
  defaultTab = "member_card",
  defaultPhoneNumber,
  defaultMemberCardNumber,
  locale,
  link,
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
  const [memberCardNumber, setMemberCardNumber] = useState(
    defaultMemberCardNumber
  );

  const onTabHeaderClick = (type) => {
    setActiveTab(type);
  };

  return (
    <form
      action={`/${locale}/users/session`}
      acceptCharset="UTF-8"
      method="post"
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={getAuthenticityToken()}
      />
      <div className="mt-4 mb-9 text-grey-300">
        {t("login_form_component.description")}
      </div>
      <div className="tabs flex mb-7 border-b border-grey-150">
        <button
          className={classNames(
            "flex-initial mr-8 pb-5 outline-none focus:outline-none border-b-2 border-primary border-opacity-0",
            activeTab === "member_card" && "font-semibold border-opacity-100"
          )}
          onClick={onTabHeaderClick.bind(this, "member_card")}
          type="button"
        >
          {t("login_form_component.member_card.tab_header")}
        </button>
        <button
          className={classNames(
            "flex-initial mr-8 pb-5 outline-none focus:outline-none border-b-2 border-primary border-opacity-0",
            activeTab === "phone_number" && "font-semibold border-opacity-100"
          )}
          onClick={onTabHeaderClick.bind(this, "phone_number")}
          type="button"
        >
          {t("login_form_component.phone_number.tab_header")}
        </button>
      </div>
      <div
        className={classNames(
          "phone_number",
          activeTab !== "phone_number" && "hidden"
        )}
      >
        <label
          className="text-grey-300 font-semibold text-xs"
          htmlFor="users_authentication_new_session_form_phone_number"
        >
          {t("login_form_component.phone_number.label")}
        </label>
        <PhoneInput
          placeholder={t("login_form_component.phone_number.ph")}
          value={phoneNumber}
          onChange={setPhoneNumber}
          country="th"
          preferredCountries={["th"]}
          disableCountryCode
          inputClass="text-grey border border-grey-200 p-1 block w-full mb-8"
          inputProps={{
            name: "users_authentication_new_session_form[phone_number]",
            autoFocus: true,
          }}
        />
      </div>
      <div
        className={classNames(
          "member_card",
          activeTab !== "member_card" && "hidden"
        )}
      >
        <label
          className="text-grey-300 font-semibold text-xs"
          htmlFor="users_authentication_new_session_form_member_card_number"
        >
          {t("login_form_component.member_card.label")}
        </label>
        <input
          placeholder={t("login_form_component.member_card.ph")}
          className="text-grey border border-grey-200 p-1 block w-full mb-8"
          type="text"
          name="users_authentication_new_session_form[member_card_number]"
          id="users_authentication_new_session_form_member_card_number"
          value={memberCardNumber}
          onChange={(e) => {
            setMemberCardNumber(e.value);
          }}
        />
      </div>
      <div className="actions">
        <input
          type="submit"
          name="commit"
          value={t("login_form_component.submit")}
          className="btn bg-primary border-0 font-semibold text-white hover:text-white cursor-auto cursor-pointer px-4 py-2 block mx-auto"
        />
      </div>
      {/* <div className="text-center mt-12">
        <div className="text-sm inline">
          {t("login_form_component.trial_account.label")}
        </div>
        <div className="text-sm inline text-brand-300 font-semibold">
          <a className="text-brand-300 pl-2" href={link.trial_account}>
            {t("login_form_component.trial_account.free_label")}
          </a>
        </div>
      </div> */}
    </form>
  );
};

LoginForm.propTypes = {
  defaultTab: PropTypes.string,
  defaultPhoneNumber: PropTypes.string,
  defaultMemberCardNumber: PropTypes.string,
  locale: PropTypes.string,
  link: PropTypes.string,
};

export default LoginForm;
