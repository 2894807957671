import { t } from "i18n-js";
import ENFlag from "images/icons/en-flag.svg";
import THFlag from "images/icons/th-flag.svg";

export function getLabel(locale) {
  if (locale === "en") {
    return t("shared.locales.en");
  }

  if (locale === "th") {
    return t("shared.locales.th");
  }

  return "";
}

export const locales = [
  { icon: ENFlag, value: "en" },
  { icon: THFlag, value: "th" },
];
