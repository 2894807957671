import React, { useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import useOnClickOutside from "hooks/useOnClickOutside";
import { countSelectedFieldOptions, toggleFieldFilters } from "utils/recipe";
import RecipeFilterFieldHeader from "./RecipeFilterFieldHeader";
import RecipeFilterFieldOptions from "./RecipeFilterFieldOptions";

const RecipeFilterDesktopMenu = ({
  field,
  options,
  isOpen,
  onOpen,
  onClose,
  selectedFilters = {},
  setSelectedFilters,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => {
    if (isOpen) onClose();
  });

  const selectedOptionCount = countSelectedFieldOptions(selectedFilters, field);
  return (
    <div ref={ref}>
      <RecipeFilterFieldHeader
        field={field}
        onClick={() => {
          if (isOpen) {
            onClose();
          } else {
            onOpen();
          }
        }}
        selectedOptionCount={selectedOptionCount}
        isAllSelected={selectedOptionCount === options.length}
      />
      <div className="relative">
        <RecipeFilterFieldOptions
          className={classNames(
            "absolute z-10 inset-x-0 max-h-recipe-filter-menu overflow-auto bg-white border border-t-0 border-grey-200 shadow-blur-40 py-7",
            isOpen ? "block" : "hidden"
          )}
          options={options}
          onSelect={(value) => {
            setSelectedFilters(
              toggleFieldFilters(field, value, selectedFilters)
            );
          }}
          isChecked={(value) => _.get(selectedFilters, [field, value], false)}
        />
      </div>
    </div>
  );
};

RecipeFilterDesktopMenu.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedFilters: PropTypes.object,
  setSelectedFilters: PropTypes.func.isRequired,
};

export default RecipeFilterDesktopMenu;
