import React, { useEffect, useState, useRef } from "react";
import { getAuthenticityToken } from "utils/getAuthenticityToken";
import { useInterval } from "utils/useInterval";
import { t } from "i18n-js";
import PropTypes from "prop-types";

const OTPForm = ({ token, defaultCode, links, recreatableAt, codeLength }) => {
  const recreatableAtTime = Date.parse(recreatableAt);
  const formRef = useRef(null);

  const [tick, setTick] = useState(1000);
  const [code, setCode] = useState(defaultCode || "");
  const [resendTimeLeft, setResendTimeLeft] = useState(
    recreatableAtTime - Date.now()
  );

  useInterval(() => {
    const diff = recreatableAtTime - Date.now();
    if (diff > -1 * tick) {
      setResendTimeLeft(diff);
    } else {
      setTick(0);
    }
  }, tick);

  useEffect(() => {
    if (code.length >= codeLength) {
      formRef.current.submit();
    }
  }, [code, codeLength]);

  const resendTimeLeftInSec = Math.round(resendTimeLeft / 1000);
  const resendTimeLeftSec = resendTimeLeftInSec % 60;
  const resendTimeLeftMin = Math.floor(resendTimeLeftInSec / 60);

  let resendBlock = (
    <div className="font-semibold">
      {t("otp_form_component.resend_in", {
        time: [
          resendTimeLeftMin.toString().padStart(2, "0"),
          resendTimeLeftSec.toString().padStart(2, "0"),
        ].join(":"),
      })}
    </div>
  );

  if (resendTimeLeft < 0) {
    resendBlock = (
      <a
        className="font-semibold text-primary"
        data-method="post"
        href={links.resend}
      >
        {t("otp_form_component.resend")}
      </a>
    );
  }

  return (
    <form
      action={links.submit}
      acceptCharset="UTF-8"
      method="post"
      ref={formRef}
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={getAuthenticityToken()}
      />
      <input
        type="hidden"
        value={token}
        name="users_authentication_verification_by_otp_form[token]"
        id="users_authentication_verification_by_otp_form_token"
      />
      <div className="verification-code mb-3">
        <input
          className="text-grey border border-grey-200 p-1 block w-full mb-8"
          placeholder="0000"
          type="text"
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          name="users_authentication_verification_by_otp_form[code]"
          id="users_authentication_verification_by_otp_form_code"
        />
      </div>
      <div className="actions text-center">
        <div className="text-grey-300 mb-1">
          {t("otp_form_component.didnt_receive")}
        </div>
        {resendBlock}
      </div>
    </form>
  );
};

OTPForm.propTypes = {
  token: PropTypes.string,
  defaultCode: PropTypes.string,
  links: PropTypes.object,
  recreatableAt: PropTypes.string,
  codeLength: PropTypes.number,
};

export default OTPForm;
