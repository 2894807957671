import React, { useState } from "react";
import PropTypes from "prop-types";
import { locale, t } from "i18n-js";
import ChevronDown from "../../images/icons/chevron-down.svg?inline";
import SuccessImage from "../../images/success.png";
import WarningIcon from "../../images/icons/warning.svg?inline";
import Modal from "../common/Modal";

const EventModal = ({ signInLink, homeLink, isRegistered }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const renderIsRegistered = () => {
    return (
      <div className="h-full flex flex-col justify-between">
        <div className="mx-auto mb-7">
          <img
            src={SuccessImage}
            alt="thank_you"
            width="102"
            height="102"
            className="m-auto"
          />
        </div>
        <h3 className="font-bold text-success-200 mb-4">
          {t("events.show.modal.registered")}
        </h3>
        <div>{t("events.show.modal.registered_content")}</div>
        <div className="text-sm mt-9">
          <a
            href={`/${locale}/events`}
            className="py-2 px-4 border border-grey-200"
          >
            <ChevronDown className="inline-block transform rotate-90 fill-current text-primary" />
            <span className="ml-3 font-semibold">
              {t("events.show.modal.back")}
            </span>
          </a>
        </div>
      </div>
    );
  };

  const renderTrialAccount = () => {
    return (
      <div className="h-full flex flex-col justify-between">
        <div className="mx-auto">
          <WarningIcon />
        </div>
        <div className="text-2xl font-bold">{t("events.show.modal.title")}</div>
        <div>{t("events.show.modal.content")}</div>
        <a
          href={signInLink}
          type="button"
          className="btn text-white bg-brand-300 text-sm font-semibold py-2 px-4"
        >
          {t("shared.modal.become_member")}
        </a>
        <div className="text-sm">
          {t("shared.continue_home.continue")}
          <a href={homeLink} className="text-brand-300 font-semibold">
            {" "}
            {t("shared.continue_home.home")}
          </a>
        </div>
      </div>
    );
  };

  return (
    <>
      <button
        type="button"
        className="btn text-white bg-brand-300 text-sm font-semibold py-2 px-4"
        onClick={toggle}
      >
        {t("events.show.register_now")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        containerClass={`bg-white mx-auto z-50 overflow-y-auto ${
          isRegistered ? null : "md:h-event-modal md:w-event-modal"
        }`}
        modalContent={
          isRegistered ? renderIsRegistered() : renderTrialAccount()
        }
      />
    </>
  );
};

EventModal.propTypes = {
  signInLink: PropTypes.string,
  homeLink: PropTypes.string,
  isRegistered: PropTypes.bool,
};

export default EventModal;
